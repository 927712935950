export default [
  {
    name: 'Login',
    path: '/login',
    exact: true,
    component: () => import('../containers/Login'),
  },
  {
    name: 'Login verification',
    path: '/login/verification',
    exact: true,
    component: () => import('../containers/LoginVerification'),
  },
];
