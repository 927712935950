
import immutable from 'immer';

import {
  UPDATE_PROFILE,
  UPDATE_PROFILE_SUCCEEDED,
  UPDATE_PROFILE_FAILED,
  FETCH_PENDING_REVIEWS,
  FETCH_PENDING_REVIEWS_SUCCEEDED,
  FETCH_PENDING_REVIEWS_FAILED,
  ADD_REVIEW_CANDIDATE,
  ADD_REVIEW_CANDIDATE_SUCCEEDED,
  ADD_REVIEW_CANDIDATE_FAILED,
  FETCH_REVIEWS,
  FETCH_REVIEWS_SUCCEEDED,
  FETCH_REVIEWS_FAILED,
  UPDATE_IMAGE,
  UPDATE_IMAGE_SUCCEEDED,
  UPDATE_IMAGE_FAILED,
  FETCH_ACCOUNT_INFO,
  FETCH_ACCOUNT_INFO_SUCCEEDED,
  FETCH_ACCOUNT_INFO_FAILED,
  FETCH_PROFILE,
  FETCH_PROFILE_SUCCEEDED,
  FETCH_PROFILE_FAILED,
} from '../../store/constants/user';
import { LOGOUT } from '../../store/constants/authentication';
import { UserState } from '../types/user';

const initialState: UserState = {
  updateProfile: {
    loading: false,
    avatarImageLoading: false,
    bodyImageLoading: false,
    faceImageLoading: false,
    optionalImageLoading: false,
    error: {},
  },
  profile: null,
  jobReviews: {
    pending: {
      pendingReviews: [],
    },
  },
  account: null,
  reviews: [],
};

export default (state: typeof initialState = initialState, action: any) =>
  immutable(state, draft => {
    switch (action.type) {
      case LOGOUT:
        return initialState;
      case UPDATE_PROFILE:
        draft.updateProfile.loading = true;
        break;
      case UPDATE_PROFILE_SUCCEEDED:
        draft.updateProfile.loading = false;
        draft.profile = action.payload.data;
        break;
      case UPDATE_PROFILE_FAILED:
        draft.updateProfile.loading = false;
        draft.updateProfile.error = action.error;
        break;
      case FETCH_PENDING_REVIEWS:
        // TODO: WTF?!
        draft.updateProfile.loading = true;
        break;
      case FETCH_PENDING_REVIEWS_SUCCEEDED:
        draft.updateProfile.loading = false;
        if (draft.jobReviews.pending.pendingReviews.length === 0 && draft.jobReviews.pending.pendingReviews.length === action.payload.pendingReviews.length) {
          break;
        }
        draft.jobReviews.pending = action.payload;
        break;
      case FETCH_PENDING_REVIEWS_FAILED:
        draft.updateProfile.loading = false;
        draft.updateProfile.error = action.error;
        break;
      case ADD_REVIEW_CANDIDATE:
        return draft;
      case ADD_REVIEW_CANDIDATE_SUCCEEDED:
        draft.updateProfile.loading = false;
        draft.jobReviews.pending.pendingReviews = draft.jobReviews.pending.pendingReviews.filter(
          review => review.applicationId !== action.applicationId,
        );
        break;
      case ADD_REVIEW_CANDIDATE_FAILED:
        draft.updateProfile.loading = false;
        draft.updateProfile.error = action.error;
        break;
      case FETCH_REVIEWS:
        draft.updateProfile.loading = true;
        break;
      case FETCH_REVIEWS_SUCCEEDED:
        draft.updateProfile.loading = false;
        draft.reviews = action.payload;
        break;
      case FETCH_REVIEWS_FAILED:
        draft.updateProfile.loading = false;
        draft.updateProfile.error = action.error;
        break;
      case UPDATE_IMAGE:
        draft.updateProfile.loading = true;
        draft.updateProfile[`${action.imageType}ImageLoading`] = true
        break;
      case UPDATE_IMAGE_SUCCEEDED:
        draft.updateProfile.loading = false;
        draft.updateProfile[`${action.imageType}ImageLoading`] = false
        if (draft.profile) {
          draft.profile[`${action.imageType}Image`] = action.payload;
        }
        break;
      case UPDATE_IMAGE_FAILED:
        draft.updateProfile.loading = false;
        draft.updateProfile[`${action.imageType}ImageLoading`] = false
        draft.updateProfile.error = action.error;
        break;
      case FETCH_ACCOUNT_INFO:
        draft.updateProfile.loading = true;
        break;
      case FETCH_ACCOUNT_INFO_SUCCEEDED:
        draft.updateProfile.loading = false;
        draft.account = action.payload;
        break;
      case FETCH_ACCOUNT_INFO_FAILED:
        draft.updateProfile.loading = false;
        draft.updateProfile.error = action.error;
        break;

      case FETCH_PROFILE:
        draft.updateProfile.loading = true;
        break;
      case FETCH_PROFILE_SUCCEEDED:
        draft.updateProfile.loading = false;
        draft.profile = action.payload;
        break;
      case FETCH_PROFILE_FAILED:
        draft.updateProfile.loading = false;
        draft.updateProfile.error = action.error;
        break;
    }
    return draft;
  });
