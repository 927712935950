import React, { ReactNode, ComponentType } from "react";
import { compose } from "redux";

import { MuiThemeProvider } from "@material-ui/core/styles";

import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import { theme as themeSelector } from "../../store/selectors/app";

import theme from "../../theme";

const mapStateToProps = createStructuredSelector({
  theme: themeSelector
});
type StateProps = ReturnType<typeof mapStateToProps>;

type OwnProps = {
  children: ReactNode
}

type Props = StateProps & OwnProps;

const ThemeProvider = (props: Props) => <MuiThemeProvider theme={theme({ type: props.theme })}>
    {props.children}
  </MuiThemeProvider>;

export default compose<ComponentType<OwnProps>>(connect(mapStateToProps, null))(ThemeProvider);