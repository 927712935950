
import { takeLatest, all, put } from 'redux-saga/effects';
import Api from 'quickjobs-api-wrapper';

import { SEND_INQUIRY, FETCH_SERVICE_INFO } from '../../store/constants/service';

import {
  sendInquirySucceeded,
  sendInquiryFailed,
  fetchServiceInfoSucceeded,
  fetchServiceInfoFailed,
} from '../../store/actions/service';

import { push } from '../../store/actions/notification';
import { intl } from '../../utils/globalIntl';

function* sendInquiry(payload: any) { 
  try {
    const result = yield Api.services.sendInquiry(payload.payload);
    yield put(sendInquirySucceeded(result.data));
    yield put(push(intl.formatMessage({ id: 'inquiry.sent.success' })));
  } catch (ex) {
    yield put(sendInquiryFailed(ex));
    yield put(push(ex.message));
  }
}

function* fetchServiceInfo() {
  try {
    const result = yield Api.services.getServiceInfo();
    yield put(fetchServiceInfoSucceeded(result));
  } catch (ex) {
    yield put(fetchServiceInfoFailed(ex));
    yield put(push(ex.message));
  }
}

export default function* serviceWatcher() {
  yield all([
    takeLatest(SEND_INQUIRY, sendInquiry),
    takeLatest(FETCH_SERVICE_INFO, fetchServiceInfo),
  ]);
}
