

export const UPDATE_PROFILE = 'USER/UPDATE_PROFILE' as const;
export const UPDATE_PROFILE_SUCCEEDED = 'USER/UPDATE_PROFILE_SUCCEEDED' as const;
export const UPDATE_PROFILE_FAILED = 'USER/UPDATE_PROFILE_FAILED' as const;

export const FETCH_PENDING_REVIEWS = 'USER/FETCH_PENDING_REVIEWS' as const;
export const FETCH_PENDING_REVIEWS_SUCCEEDED =
  'USER/FETCH_PENDING_REVIEWS_SUCCEEDED' as const;
export const FETCH_PENDING_REVIEWS_FAILED = 'USER/FETCH_PENDING_REVIEWS_FAILED' as const;

export const ADD_REVIEW_CANDIDATE = 'USER/ADD_REVIEW_CANDIDATE' as const;
export const ADD_REVIEW_CANDIDATE_SUCCEEDED =
  'USER/ADD_REVIEW_CANDIDATE_SUCCEEDED' as const;
export const ADD_REVIEW_CANDIDATE_FAILED = 'USER/ADD_REVIEW_CANDIDATE_FAILED' as const;

export const FETCH_REVIEWS = 'USER/FETCH_REVIEWS' as const;
export const FETCH_REVIEWS_SUCCEEDED = 'USER/FETCH_REVIEWS_SUCCEEDED' as const;
export const FETCH_REVIEWS_FAILED = 'USER/FETCH_REVIEWS_FAILED' as const;

export const UPDATE_IMAGE = 'USER/UPDATE_IMAGE' as const;
export const UPDATE_IMAGE_SUCCEEDED = 'USER/UPDATE_IMAGE_SUCCEEDED' as const;
export const UPDATE_IMAGE_FAILED = 'USER/UPDATE_IMAGE_FAILED' as const;

export const FETCH_ACCOUNT_INFO = 'USER/FETCH_ACCOUNT_INFO' as const;
export const FETCH_ACCOUNT_INFO_SUCCEEDED = 'USER/FETCH_ACCOUNT_INFO_SUCCEEDED' as const;
export const FETCH_ACCOUNT_INFO_FAILED = 'USER/FETCH_ACCOUNT_INFO_FAILED' as const;

export const FETCH_PROFILE = 'USER/FETCH_PROFILE' as const;
export const FETCH_PROFILE_SUCCEEDED = 'USER/FETCH_PROFILE_SUCCEEDED' as const;
export const FETCH_PROFILE_FAILED = 'USER/FETCH_PROFILE_FAILED' as const;
