import _some from 'lodash/some';
import _reduce from 'lodash/reduce';
import { Package, Plan, JOB_TERM, BasicPlan } from 'quickjobs-api-wrapper';
import { onTermCondition } from './jobTerm';

const getCreditSum = (arr: any[], jobTerm: JOB_TERM) =>
  _reduce(
    arr,
    (sum, item) => {
      if (sum === -1) {
        return sum;
      }
      const addition = onTermCondition(jobTerm, item.jobCredits.oneTime, item.jobCredits.longTerm, item.jobCredits.fullTime);
      return sum + addition;
    },
    0,
  );

export const calcCredits = (
  accountInfo: any,
  jobTerm: JOB_TERM,
) => {
  const { userPackages, userPlans, userBasicPlan } = accountInfo;

  const packagesJobs = getCreditSum(userPackages, jobTerm);
  const planJobs = getCreditSum(userPlans, jobTerm);
  const freeCredits = getCreditSum([userBasicPlan], jobTerm);

  if (_some([packagesJobs, planJobs, freeCredits], credits => credits === -1)) {
    return '∞';
  }

  return packagesJobs + planJobs + freeCredits;
};

export const getCreditsByTerm = (term: JOB_TERM, sku: Package | Plan | BasicPlan) => {
  return onTermCondition(term, sku.jobCredits.oneTime, sku.jobCredits.longTerm, sku.jobCredits.fullTime);
}

export const getPrimaryCredits = (boughtSku: Package | Plan) => {
  if (!boughtSku || !boughtSku.jobCredits) {
    return 0;
  }
  return getCreditsByTerm(boughtSku.jobTerm, boughtSku);
};
