import React, { Fragment } from 'react';
import _noop from 'lodash/noop';
import Api, { JOB_TERM } from 'quickjobs-api-wrapper';
import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import { push, RouterState } from 'connected-react-router';
import { FormattedMessage } from 'react-intl';
import Classnames from 'classnames';
import { withStyles, WithStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Avatar from '@material-ui/core/Avatar';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import FemalePlaceholderIcon from '../../assets/img/NoPhotoFemale.svg';
import MalePlaceholderIcon from '../../assets/img/NoPhotoMale.svg';
import Logo from '../../components/Logo';
import OffersPopper from '../../components/OffersPopper';
import UserMenus from '../../components/UserMenu';
import NewOfferModal from '../../modals/NewOffer';
import * as userActions from '../../store/actions/user';
import * as appActions from '../../store/actions/app';
import * as authActions from '../../store/actions/authentication';
import * as selectors from '../../store/selectors';

import { AppState } from '../../store/types/app';

import ServerSentEvents from '../../components/ServerSentEvents';
import Loader from '../../assets/img/initialLoader.svg';
import HeaderToolbar from './HeaderToolbar';
import MenuItem from './MenuItem';

import styles from './styles';
import { EVENT_ACCOUNT_UPDATED } from '../../constants';
import routeCollection, * as routes from '../../routes/masterview';
import { LocationDescriptorObject } from 'history';
import { RouteBuild, RouteInstance } from '../../routes/builder';
import { UserState } from '../../store/types/user';
import LoadingSpinnerScreen from '../LoadingSpinnerScreen';
import { onTermCondition } from '../../utils/jobTerm';
import { JobState } from '../../store/types/jobs';
import PrimaryButton from '../buttons/PrimaryButton';
import { Button } from '@material-ui/core';

const mapDispatchToProps = (dispatch: Dispatch) => ({
  redirect: (path: LocationDescriptorObject) => dispatch(push(path)),
  openNewOfferModal: (route: RouteBuild) => dispatch(appActions.openNewOfferModal(route)),
  closeNewOfferModal: (route: RouteBuild) => dispatch(appActions.closeNewOfferModal(route)),
  fetchPendingReviews: () => dispatch(userActions.fetchPendingReviews()),
  fetchAccountInfo: () => dispatch(userActions.fetchAccountInfo()),
  fetchProfile: () => dispatch(userActions.fetchProfile()),
  logout: () => dispatch(authActions.logout())
});
type DispatchProps = ReturnType<typeof mapDispatchToProps>;

const mapStateToProps = (state: {
  app: AppState;
  user: UserState;
  router: RouterState;
  authentication: any;
  jobs: JobState;
}) => {
  const jobTerm = (state.router.location.state?.jobTerm ?? JOB_TERM.FULL_TIME) as JOB_TERM;

  return {
    location: state.router.location,
    profile: selectors.getLoggedInUserProfile(state),
    isLoggedIn: !!state.authentication.oauth?.accessToken,
    pendingReviews: selectors.getPendingReviews(state),
    drafts: state.jobs.drafts,
    jobTerm
  };
};
type StateProps = ReturnType<typeof mapStateToProps>;

type OwnProps = {
  children: any;
};

type Props = StateProps & DispatchProps & WithStyles<typeof styles> & OwnProps;

interface State {
  isReady: boolean;
  offersPopperOpen: boolean;
  offersPopperAnchor: any | null;
  offersPopperId: any | null;
  userMenuAnchor: any | null;
}

class MasterView extends React.PureComponent<Props, State> {
  static whyDidYouRender = true;

  constructor(props: Props) {
    super(props);
    this.state = {
      isReady: false,
      offersPopperOpen: false,
      offersPopperAnchor: null,
      offersPopperId: null,
      userMenuAnchor: null
    };
  }

  componentDidMount = async () => {
    const { fetchPendingReviews, fetchAccountInfo, fetchProfile, logout, isLoggedIn } = this.props;
    try {
      if (isLoggedIn) {
        this.setState({ isReady: true });
        fetchProfile();
        fetchAccountInfo();
        fetchPendingReviews();
      }
    } catch (err) {
      Api.restoreUserToken(null);
      logout();
    }
  };

  handleClickOffers = (event) => {
    const { currentTarget } = event;
    this.setState((state) => ({
      offersPopperAnchor: currentTarget,
      offersPopperOpen: !state.offersPopperOpen,
      offersPopperId: !state.offersPopperOpen ? 'offers-popper' : null
    }));
  };

  handleClickMenu = (event) => {
    const { currentTarget } = event;
    this.setState({
      userMenuAnchor: currentTarget
    });
  };

  handleCloseMenu = () => {
    this.setState({
      userMenuAnchor: null
    });
  };

  render() {
    const { isReady } = this.state;
    const {
      pendingReviews,
      redirect,
      classes,
      location,
      profile,
      openNewOfferModal,
      closeNewOfferModal
    } = this.props;
    if (!profile) {
      return <LoadingSpinnerScreen />;
    }
    const group = routeCollection.findMatching(location.pathname)?.groupId;
    const isActive = (page: RouteInstance<any, any, any>) => page.isMatching(location.pathname);

    let jobTerm: JOB_TERM | null = null;
    if (Object.values(JOB_TERM).includes(group as JOB_TERM)) {
      jobTerm = group as JOB_TERM;
    }

    const isNewOfferModalOpened = !!(location.hash === '#create' && jobTerm);

    return (
      <Fragment>
        <ServerSentEvents.Consumer
          eventName={EVENT_ACCOUNT_UPDATED}
          action={this.props.fetchAccountInfo}
        />
        <AppBar
          position="fixed"
          color="primary"
          className={Classnames(classes.appBar, {
            [classes.showNoJobOffers]: !jobTerm
          })}
        >
          <Toolbar className={classes.toolbar} style={{
            gap:10
          }}>
            <div
              onKeyPress={_noop}
              className={classes.logoWrap}
              onClick={() => redirect(routes.HOME.build({}))}
              role="button"
              tabIndex={0}
            >
              <Logo />
            </div>
            <div className={classes.toolbarFlex} />
            {jobTerm ? (
              <OffersPopper
                open={this.state.offersPopperOpen}
                anchorEl={this.state.offersPopperAnchor}
                id={this.state.offersPopperId}
                onClose={() => this.setState({ offersPopperOpen: false })}
                jobTerm={jobTerm}
              />
            ) : null}
            <div className={classes.buttonWrapper} style={{ gap:5}}>
              <PrimaryButton
                corners="rounded"
                // isActive={location.pathname === "/cenik"}

                onClick={() => {
                  redirect(routes.PRICING.build('',{
                    hash:jobTerm?`#${jobTerm}`:undefined
                  } ));
          
                }}
              >
                <FormattedMessage tagName="span" id="pricing.title" />
              </PrimaryButton>
              <MenuItem
                isActive={group === JOB_TERM.FULL_TIME}
                onClick={() => {
                  redirect(routes.FULL_TIME_OFFERS.build({}));
                }}
              >
                <FormattedMessage tagName="span" id="masterview.appbar.title.fullTime" />
              </MenuItem>
              <MenuItem
                isActive={group === JOB_TERM.LONG_TERM}
                onClick={() => {
                  redirect(routes.LONG_TERM_OFFERS.build({}));
                }}
              >
                <FormattedMessage tagName="span" id="masterview.appbar.title.longTerm" />
              </MenuItem>
              <MenuItem
                isActive={group === JOB_TERM.ONE_TIME}
                onClick={() => {
                  redirect(routes.ONE_TIME_OFFERS.build({}));

                }}
              >
                <FormattedMessage tagName="span" id="masterview.appbar.title.oneTime" />
              </MenuItem>
              <MenuItem
                isActive={isActive(routes.REVIEWS)}
                badge={pendingReviews && pendingReviews.length}
                onClick={() => redirect(routes.REVIEWS.build({}))}
              >
                <FormattedMessage tagName="span" id="masterview.appbar.rating" />
              </MenuItem>
            </div>
            <MenuItem
              isActive={isActive(routes.HOME)}
              onClick={() => redirect(routes.HOME.build({}))}
            >
              <FontAwesomeIcon
                icon={['far', 'lightbulb']}
                className={classes.buttonIcon}
                size="lg"
              />
              <FormattedMessage tagName="span" id="masterview.appbar.knowhow" />
            </MenuItem>
            <Button
              style={{
                color: 'white',
                border: '2px solid #2fbd68',
                background: 'transparent',
                borderRadius: '10px'
              }}
              onClick={() => {
                window.location.href = 'https://candidates.quickjobs.cz';
              }}
            >
              <FormattedMessage tagName="span" id="database.title" />
            </Button>

            <div className={classes.headerUserWrap} onClick={(e) => this.handleClickMenu(e)}>
              <Avatar
                alt="Name Surname"
                src={
                  profile.avatarImage?.url ??
                  (profile.gender === 'male' ? MalePlaceholderIcon : FemalePlaceholderIcon)
                }
                className={classes.avatar}
              />
              {profile.givenName && profile.familyName ? (
                <Typography
                  onClick={(e) => this.handleClickMenu(e)}
                  className={classes.userName}
                  variant="body1"
                >
                  {profile.givenName} {profile.familyName}
                </Typography>
              ) : null}
            </div>

            <UserMenus
              anchorEl={this.state.userMenuAnchor}
              onClose={() => this.handleCloseMenu()}
            />
          </Toolbar>
          {jobTerm ? (
            <HeaderToolbar
              jobTerm={jobTerm}
              handleOpenForm={() => openNewOfferModal(location)}
              openOffersPopper={this.handleClickOffers}
            />
          ) : null}
        </AppBar>
        {jobTerm ? (
          <NewOfferModal
            jobTerm={jobTerm}
            initialData={
              location.state?.initialFormData ||
              onTermCondition(
                jobTerm,
                this.props.drafts.oneTime,
                this.props.drafts.longTerm,
                this.props.drafts.fullTime
              )
            }
            open={isNewOfferModalOpened}
            onClose={() => closeNewOfferModal(location)}
          />
        ) : null}
        {/*<CookieBar /> Dali chtel schovat    */}
        <div
          id="spacer"
          className={Classnames(classes.spacer, {
            [classes.spacerProfile]: isActive(routes.MY_PROFILE)
          })}
        />

        {isReady ? (
          { ...this.props.children }
        ) : (
          <div className={classes.loaderWrap}>
            <img src={Loader} alt="loading" />
          </div>
        )}
      </Fragment>
    );
  }
}

export const PlainMasterView = withStyles(styles)(MasterView);
export default connect(mapStateToProps, mapDispatchToProps)(PlainMasterView);
