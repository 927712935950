import {
  SEND_INQUIRY,
  SEND_INQUIRY_SUCCEEDED,
  SEND_INQUIRY_FAILED,
  FETCH_SERVICE_INFO,
  FETCH_SERVICE_INFO_SUCCEEDED,
  FETCH_SERVICE_INFO_FAILED
} from '../../store/constants/service';

export const sendInquiry = (payload: {
  email: string;
  message: string;
  name?: string;
  subject?: string;
}) => ({
  type: SEND_INQUIRY,
  payload
});
type TYPE_SEND_INQUIRY = ReturnType<typeof sendInquiry>;

export const sendInquirySucceeded = (payload: any) => ({
  type: SEND_INQUIRY_SUCCEEDED,
  payload
});
type TYPE_SEND_INQUIRY_SUCCEEDED = ReturnType<typeof sendInquirySucceeded>;

export const sendInquiryFailed = (error: Error) => ({
  type: SEND_INQUIRY_FAILED,
  error
});
type TYPE_SEND_INQUIRY_FAILED = ReturnType<typeof sendInquiryFailed>;

export const fetchServiceInfo = () => ({
  type: FETCH_SERVICE_INFO
});
type TYPE_FETCH_SERVICE_INFO = ReturnType<typeof fetchServiceInfo>;

export const fetchServiceInfoSucceeded = (payload: any) => ({
  type: FETCH_SERVICE_INFO_SUCCEEDED,
  payload
});
type TYPE_FETCH_SERVICE_INFO_SUCCEEDED = ReturnType<typeof fetchServiceInfoSucceeded>;

export const fetchServiceInfoFailed = (error: Error) => ({
  type: FETCH_SERVICE_INFO_FAILED,
  error
});
type TYPE_FETCH_SERVICE_INFO_FAILED = ReturnType<typeof fetchServiceInfoFailed>;

export type ACTIONS =
  | TYPE_SEND_INQUIRY
  | TYPE_SEND_INQUIRY_SUCCEEDED
  | TYPE_SEND_INQUIRY_FAILED
  | TYPE_FETCH_SERVICE_INFO
  | TYPE_FETCH_SERVICE_INFO_SUCCEEDED
  | TYPE_FETCH_SERVICE_INFO_FAILED;
