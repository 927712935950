import { persistReducer, createMigrate } from 'redux-persist';
import { setAutoFreeze } from 'immer';
import storage from 'redux-persist/lib/storage';

import { isDevelopment } from '../../utils/env';

import app from './app';
import notification from './notification';
import authentication from './authentication';
import user from './user';
import jobs from './jobs';
import applications from './applications';
import feedback from './feedback';
import service from './service';

import migrations, { VERSION } from '../migrations';

setAutoFreeze(false);

const applyPersistence = (
  key: string,
  reducer: any,
  blacklist: string[] = [],
) =>
  persistReducer(
    {
      key,
      storage,
      version: VERSION,
      migrate: createMigrate(migrations(key), { debug: isDevelopment }),
      blacklist,
    },
    reducer,
  );

const reducers = {
  app: applyPersistence('app', app),
  notification,
  authentication: applyPersistence('authentication', authentication, [
    'login',
    'verification',
  ]),
  user: applyPersistence('user', user, ['updateProfile']),
  jobs: applyPersistence('jobs', jobs, ['calls']),
  applications: applyPersistence('applications', applications, ['calls']),
  feedback,
  service,
};

export default reducers;
