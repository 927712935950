import {
  SEND_VERIFICATION,
  SEND_VERIFICATION_SUCCEEDED,
  SEND_VERIFICATION_FAILED,
  LOGIN,
  LOGIN_SUCCEEDED,
  LOGIN_FAILED,
  LOGOUT,
  REQUEST_FCM_TOKEN,
  REQUEST_FCM_TOKEN_SUCCEEDED,
} from '../../store/constants/authentication';
import { TokenPair } from 'quickjobs-api-wrapper';

export const sendVerification = (phoneNumber: string) => ({
  type: SEND_VERIFICATION,
  phoneNumber,
});

export const sendVerificationSucceeded = (payload: any) => ({
  type: SEND_VERIFICATION_SUCCEEDED,
  payload,
});

export const sendVerificationFailed = (error: Error) => ({
  type: SEND_VERIFICATION_FAILED,
  error,
});

export const login = (verificationCode: string) => ({
  type: LOGIN,
  verificationCode,
});

export const loginSucceeded = (payload: { oauth: TokenPair }) => ({
  type: LOGIN_SUCCEEDED,
  payload,
});

export const loginFailed = (error: Error) => ({
  type: LOGIN_FAILED,
  error,
});

export const logout = () => {
  return {
    type: LOGOUT,
  };
};
export type TYPE_LOGOUT = ReturnType<typeof logout>;

export const requestFcmToken = () => ({
  type: REQUEST_FCM_TOKEN,
});

export const requestFcmTokenSucceeded = (payload: any) => ({
  type: REQUEST_FCM_TOKEN_SUCCEEDED,
  payload,
});
