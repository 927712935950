
import immutable from 'immer';
import moment from 'moment';
// import _countBy from 'lodash/countBy';

import { JobState } from '../types/jobs';


import {
  UPDATE_JOB_OFFER_DRAFT,
  CREATE_OFFER,
  CREATE_OFFER_SUCCEEDED,
  CREATE_OFFER_FAILED,
  GET_MY_JOBS,
  GET_MY_JOBS_SUCCEEDED,
  GET_MY_JOBS_FAILED,
  GET_JOB,
  GET_JOB_SUCCEEDED,
  GET_JOB_FAILED,
  PUSH_NEW_STATS,
  PUSH_JOB_ENDED,
  PUSH_JOB_BANNED,
  CANCEL_JOB,
  CANCEL_JOB_SUCCEEDED,
  CANCEL_JOB_FAILED,
} from '../constants/jobs';
import { LOGOUT } from '../constants/authentication';
import { arrayToObject } from '../../utils/reducerHelpers';

import { ACTIONS } from '../actions/jobs';
import { Job, JOB_TERM } from 'quickjobs-api-wrapper';

const initialState: JobState = {
  byId: {},
  drafts: {
    oneTime: {},
    longTerm: {},
    fullTime: {}
  },
  views: {
    allOneTimeOffers: {
      ids: [],
      totalCount: 0,
      pageInfo: null,
    },
    allLongTermOffers: {
      ids: [],
      totalCount: 0,
      pageInfo: null,
    },
    allFullTimeOffers: {
      ids: [],
      totalCount: 0,
      pageInfo: null,
    },
  },
  stats: {
    byJobId: {},
  },
  calls: {
    createOffer: {
      isLoading: false,
      issuedAt: null,
      error: null,
    },
    createDDBOffer: {
      isLoading: false,
      issuedAt: null,
      error: null,
    },
    cancelJob: {
      isLoading: false,
      issuedAt: null,
      error: null,
    },
    getAllOneTimeOffers: {
      isLoading: false,
      issuedAt: null,
      error: null,
    },
    getAllLongTermOffers: {
      isLoading: false,
      issuedAt: null,
      error: null,
    },
    getAllFullTimeOffers: {
      isLoading: false,
      issuedAt: null,
      error: null,
    },
    getDetail: {
      isLoading: false,
      issuedAt: null,
      error: null,
    },
  },
};

export default (state: JobState = initialState, action: ACTIONS): JobState =>
  immutable(state, draft => {
    switch (action.type) {        
      case LOGOUT:
        return initialState;
      case UPDATE_JOB_OFFER_DRAFT:
        const job_draft = action.payload.draft;
        if (action.payload.term === JOB_TERM.ONE_TIME) {
          draft.drafts.oneTime = job_draft;
        } else if (action.payload.term === JOB_TERM.LONG_TERM) {
          draft.drafts.longTerm = job_draft;
        } else if (action.payload.term === JOB_TERM.FULL_TIME) {
          draft.drafts.fullTime = job_draft;
        } else {
          throw new Error("Invalid term");
        }
        break;
   
      case CREATE_OFFER:
        draft.calls.createOffer = {
          isLoading: true,
          issuedAt: new Date().getTime(),
          error: null,
        };
        break;
      case CREATE_OFFER_SUCCEEDED: {
        const job: Job = action.payload;
        let view: string;
        if (job.term === JOB_TERM.ONE_TIME) {
          draft.drafts.oneTime = {};
          view = 'allOneTimeOffers';
        } else if (job.term === JOB_TERM.LONG_TERM) {
          draft.drafts.longTerm = {};
          view = 'allLongTermOffers';
        } else if (job.term === JOB_TERM.FULL_TIME) {
          draft.drafts.fullTime = {};
          view = 'allFullTimeOffers';
        } else {
          throw new Error("Invalid term");
        }

        draft.calls.createOffer.isLoading = false;
        draft.byId[job.id] = job;
        draft.views[view].ids = [job.id, ...draft.views[view].ids];
        break;
      }
      case CREATE_OFFER_FAILED:
        draft.calls.createOffer.isLoading = false;
        draft.calls.createOffer.error = action.error;
        break;
      case GET_MY_JOBS: {
        const term = action.queryParams.term;
        let call: string;
        if (term === JOB_TERM.ONE_TIME) {
          call = 'getAllOneTimeOffers'
        } else if (term === JOB_TERM.LONG_TERM) {
          call = 'getAllLongTermOffers';
        } else if (term === JOB_TERM.FULL_TIME) {
          call = 'getAllFullTimeOffers';
        } else {
          throw new Error("Invalid term");
        }

        draft.calls[call] = {
          isLoading: true,
          issuedAt: new Date().getTime(),
          error: null,
        };
        break;
      }
      case GET_MY_JOBS_SUCCEEDED:
        /* const latestStoredJob = draft.jobs.views[jobView].ids.length
          ? draft.jobs.byId[draft.jobs.views[jobView].ids[0]]
          : null;

        const numberOfNewerJobs =
          latestStoredJob &&
          _countBy(action.payload.jobs, (j: Job) =>
            moment(j.createdAt).isBefore(latestStoredJob.createdAt),
          );

        let doesRequireReset = false;
        if (
          draft.jobs.views[jobView].totalCount + numberOfNewerJobs <
          action.payload.totalCount
        ) {
          doesRequireReset = true;
        } */
        if (action.payload.jobTerm === JOB_TERM.LONG_TERM) {
          const ids = new Set([
            ...draft.views.allLongTermOffers.ids,
            ...action.payload.jobs.map(j => j.id),
          ]);
          draft.views.allLongTermOffers = {
            ids: Array.from(ids),
            totalCount: action.payload.totalCount,
            pageInfo: action.payload.pageInfo,
          };
          draft.calls.getAllLongTermOffers.isLoading = false;
        } else if (action.payload.jobTerm === JOB_TERM.FULL_TIME) {
          const ids = new Set([
            ...draft.views.allFullTimeOffers.ids,
            ...action.payload.jobs.map(j => j.id),
          ]);
          draft.views.allFullTimeOffers = {
            ids: Array.from(ids),
            totalCount: action.payload.totalCount,
            pageInfo: action.payload.pageInfo,
          };
          draft.calls.getAllFullTimeOffers.isLoading = false;
        } else if (action.payload.jobTerm === JOB_TERM.ONE_TIME) {
          const ids = new Set([
            ...draft.views.allOneTimeOffers.ids,
            ...action.payload.jobs.map(j => j.id),
          ]);
          draft.views.allOneTimeOffers = {
            ids: Array.from(ids),
            totalCount: action.payload.totalCount,
            pageInfo: action.payload.pageInfo,
          };
          draft.calls.getAllOneTimeOffers.isLoading = false;
        } else {
          throw new Error("Invalid term");
        }

        draft.byId = {
          ...draft.byId,
          ...arrayToObject(action.payload.jobs),
        };

        draft.stats.byJobId = {
          ...draft.stats.byJobId,
          ...arrayToObject(action.payload.stats, 'jobId'),
        };

        break;
      case GET_MY_JOBS_FAILED:
        if (action.payload.jobTerm === JOB_TERM.LONG_TERM) {
          draft.calls.getAllLongTermOffers.isLoading = false;
          draft.calls.getAllLongTermOffers.error = action.error;
        } else if (action.payload.jobTerm === JOB_TERM.ONE_TIME) {
          draft.calls.getAllOneTimeOffers.isLoading = false;
          draft.calls.getAllOneTimeOffers.error = action.error;
        } else if (action.payload.jobTerm === JOB_TERM.FULL_TIME) {
          draft.calls.getAllFullTimeOffers.isLoading = false;
          draft.calls.getAllFullTimeOffers.error = action.error;
        } else {
          throw new Error("Invalid term");
        }
        break;

      case GET_JOB:
        draft.calls.getDetail.isLoading = true;
        break;
      case GET_JOB_SUCCEEDED:
        draft.calls.getDetail.isLoading = false;
        draft.byId[action.payload.data.id] = action.payload.data;
        draft.stats.byJobId[action.payload.data.id] = action.payload.stats;
        break;
      case GET_JOB_FAILED:
        draft.calls.getDetail.isLoading = false;
        draft.calls.getDetail.error = action.error;
        break;

      case PUSH_NEW_STATS:
        draft.stats.byJobId[action.payload.jobId] = action.payload;
        break;

      case PUSH_JOB_ENDED:
      case PUSH_JOB_BANNED:
        draft.byId[action.payload.id] = {
          ...draft.byId[action.payload.id],
          ...action.payload,
        };
        break;
      case CANCEL_JOB:
        draft.calls.cancelJob = {
          isLoading: true,
          issuedAt: new Date().getTime(),
          error: null,
        };
        (draft.byId[action.id] || {}).status = 'inactive';
        (draft.byId[action.id] || {}).offerExpiresAt = new Date().toISOString();
        break;
      case CANCEL_JOB_SUCCEEDED:
        draft.calls.cancelJob.isLoading = false;
        break;
      case CANCEL_JOB_FAILED:
        const failedCancelJob = draft.byId[action.jobId] || {};
        draft.calls.cancelJob.isLoading = false;
        failedCancelJob.status = 'active';
        failedCancelJob.offerExpiresAt = moment(failedCancelJob.createdAt)
          .add(failedCancelJob.offerDuration, 'milliseconds')
          .toDate()
          .toISOString();
        break;
    }
    return draft;
  });
