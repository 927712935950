

import { createSelector } from 'reselect';
import {UserState} from '../types/user';

const selector = (state): UserState => state.user;

export const getLoggedInUserProfile = createSelector(
  selector,
  app => app.profile,
);

export const getPendingReviews = createSelector(
  selector,
  app => app.jobReviews.pending.pendingReviews,
);
