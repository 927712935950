import { PUSH, CLOSE, PROCESS_QUEUE } from '../../store/constants/notification';

export const push = (message, actionMessage?, actionCallback?) => ({
  type: PUSH,
  message,
  actionMessage,
  actionCallback,
});

export const close = () => ({
  type: CLOSE,
});

export const processQueue = () => ({
  type: PROCESS_QUEUE,
});
