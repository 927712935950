import { StyleRules, Theme } from "@material-ui/core/styles";

export default (theme: Theme): StyleRules => ({
  root: {
    height: '100vh',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  oval: {
    height: '210px',
    width: '210px',
    backgroundColor: 'rgba(0,47,73,0.05)',
    borderRadius: '100%',
    marginBottom: '42px',

    '& h2': {
      color: theme.palette.primary.main,
      fontFamily: 'Montserrat',
      fontSize: '56px',
      fontWeight: 700,
      textAlign: 'center',
      margin: '50px 0 15px 0',
    },

    '& p': {
      color: theme.palette.primary.main,
      fontFamily: 'Montserrat',
      fontSize: '18px',
      textAlign: 'center',
      fontWeight: 500,
    },
  },
  body: {
    textAlign: 'center',
    '& h1': {
      color: theme.palette.primary.main,
      fontFamily: 'Montserrat',
      fontSize: '24px',
      fontWeight: 500,
      textAlign: 'center',
      marginBottom: '24px',
    },

    '& h2': {
      opacity: '0.6',
      color: theme.palette.primary.main,
      fontFamily: 'Montserrat',
      fontSize: '16px',
      fontWeight: 300,
      width: '437px',
      margin: '0 auto',
      textAlign: 'center',
      marginBottom: '40px',
    },
  },
});
