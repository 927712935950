import { createStore, applyMiddleware, compose, combineReducers } from 'redux';
import createSagaMiddleware from 'redux-saga';
import { isDevelopment } from '../utils/env';
import { persistStore } from 'redux-persist';
import { connectRouter, routerMiddleware } from 'connected-react-router';

import reducers from './reducers';
import sagas from './sagas';

export default (history: any) => {
  const routeMiddleware = routerMiddleware(history);
  const sagaMiddleware = createSagaMiddleware();

  const middlewares = [sagaMiddleware, routeMiddleware];

  const composeEnhancers =
    isDevelopment && (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
      ? (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({ trace: true })
      : compose;

  const store = createStore(
    combineReducers({
      router: connectRouter(history),
      ...reducers,
    }),
    composeEnhancers(applyMiddleware(...middlewares)),
  );
  const persistor = persistStore(store);
  // persistor.purge();

  sagaMiddleware.run(sagas);

  return { store, persistor };
};
