

import { createSelector } from 'reselect';

const selector = state => state.notification;
// $FlowFixMe
export const open = createSelector(
  selector,
  app => app.open,
);
// $FlowFixMe
export const queue = createSelector(
  selector,
  app => app.queue,
);
// $FlowFixMe
export const messageInfo = createSelector(
  selector,
  app => app.messageInfo,
);
