

export const SEND_INQUIRY = 'SERVICE/SEND_INQUIRY' as const;
export const SEND_INQUIRY_SUCCEEDED = 'SERVICE/SEND_INQUIRY_SUCCEEDED' as const;
export const SEND_INQUIRY_FAILED = 'SERVICE/SEND_INQUIRY_FAILED' as const;

export const FETCH_SERVICE_INFO = 'SERVICE/FETCH_SERVICE_INFO' as const;
export const FETCH_SERVICE_INFO_SUCCEEDED =
  'SERVICE/FETCH_SERVICE_INFO_SUCCEEDED' as const;
export const FETCH_SERVICE_INFO_FAILED = 'SERVICE/FETCH_SERVICE_INFO_FAILED' as const;
