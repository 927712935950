

export default (key: string) => (state: any) => {
  if (key === 'jobs') {
    return state.setIn(['jobs', 'myJobs', 'jobs'], {
      byId: {},
      allOneTimeOfferIds: [],
    });
  }
  return state;
};
