import React from "react";
import 'redux-persist';
import { Provider as StateProvider } from 'react-redux';
import { PersistGate as StatePersistGate } from 'redux-persist/integration/react';

import getStore from '../store';
import { History } from "history";

interface Props {
  history: History<any>;
  children: React.ReactNode;
}

export default function StateInit(props: Props) {
  const { store, persistor } = getStore(props.history);

  return (
    <StateProvider store={store}>
      <StatePersistGate persistor={persistor}>
        {props.children}
      </StatePersistGate>
    </StateProvider>
  );
}
