import React from "react";

import deeply from "../../utils/deeply";
import _mapKeys from "lodash/mapKeys";
import _camelCase from "lodash/camelCase";

import EventSourceContext from "./context";

type Props = {
  eventSource: any | null;
  eventName: string;
  action: (data: any, e: any) => void;
};

class ServerSentEventsConsumer extends React.Component<Props> {

  componentDidMount() {
    this.listen();
  }

  componentDidUpdate(prevProps) {
    this.stopListening(prevProps.action);
    this.listen();
  }

  componentWillUnmount() {
    this.stopListening(this.props.action);
  }

  listen() {
    if (this.props.eventSource) {
      this.props.eventSource.addEventListener(this.props.eventName, this.props.action);
    }
  }

  stopListening(action) {
    if (this.props.eventSource) {
      this.props.eventSource.removeEventListener(this.props.eventName, action);
    }
  }

  render() {
    return null;
  }
}

const actionWrapper = (action: Props['action']) => (e: any) => {
  const parsed = JSON.parse(e.data);
  const inCamelCase = deeply(_mapKeys)(parsed, (value, key) => _camelCase(key));
  action(inCamelCase, e);
};

export default (({
  eventName,
  action
}: {
  eventName: string;
  action: Props["action"];
}) => <EventSourceContext.Consumer>
    {eventSource => <ServerSentEventsConsumer eventSource={eventSource} eventName={eventName} action={actionWrapper(action)} />}
  </EventSourceContext.Consumer>);