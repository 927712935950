import { createSelector } from 'reselect';
import { JOB_TERM } from 'quickjobs-api-wrapper';
import { orderBy as _orderBy } from 'lodash';

import { ServiceState } from '../types/service';
import { onTermCondition } from '../../utils/jobTerm';

const selector = (state): ServiceState => state.service;

export const getServiceInfo = createSelector(selector, state => state);

export const isServiceInfoLoaded = createSelector(
  selector,
  state =>
    !state.calls.fetchInfo.isLoading &&
    !state.calls.fetchInfo.error &&
    state.calls.fetchInfo.issuedAt
);

export const isServiceInfoLoading = createSelector(
  selector,
  state => state.calls.fetchInfo.isLoading
);

export const getJobOfferConfig = createSelector(selector, state => (term: JOB_TERM) => {
  const config = state.jobOfferConfig;
  if (!config) {
    throw new Error('Uninitialized job offer config');
  }
  return onTermCondition(term, config.oneTime, config.longTerm, config.fullTime);
});

export const getAvailablePackages = createSelector(selector, state => {
  const sortedPackages = _orderBy(state.packages, ['price'], ['asc']);
  return (term: JOB_TERM) => sortedPackages.filter(pckg => pckg.jobTerm === term);
});
