

export const SEND_VERIFICATION = 'AUTHENTICATION/SEND_VERIFICATION' as const;
export const SEND_VERIFICATION_SUCCEEDED =
  'AUTHENTICATION/SEND_VERIFICATION_SUCCEEDED' as const;
export const SEND_VERIFICATION_FAILED =
  'AUTHENTICATION/SEND_VERIFICATION_FAILED' as const;

export const LOGIN = 'AUTHENTICATION/LOGIN' as const;
export const LOGIN_SUCCEEDED = 'AUTHENTICATION/LOGIN_SUCCEEDED' as const;
export const LOGIN_FAILED = 'AUTHENTICATION/LOGIN_FAILED' as const;

export const REQUEST_FCM_TOKEN = 'AUTHENTICATION/REQUEST_FCM_TOKEN' as const;
export const REQUEST_FCM_TOKEN_SUCCEEDED =
  'AUTHENTICATION/REQUEST_FCM_TOKEN_SUCCEEDED' as const;

export const LOGOUT = 'AUTHENTICATION/LOGOUT' as const;
