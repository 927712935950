import { createMuiTheme } from '@material-ui/core/styles';

const themeWrapper = (options) => {
  if (!options) {
    options = {
      type: 'light'
    };
  }
  return createMuiTheme({
    palette: {
      background: {
        default: '#FFFFFF'
      },
      primary: {
        light: '#355875',
        main: '#002f49',
        dark: '#000522',
        contrastText: '#ffffff'
      },
      secondary: {
        light: '#8cffb7',
        main: '#2fbd68',
        dark: '#159c59',
        contrastText: '#ffffff'
      },
      type: options.type
    },
    typography: {
      // Use the system font instead of the default Roboto font.
      fontFamily: [
        'Montserrat',
        '-apple-system',
        'BlinkMacSystemFont',
        '"Segoe UI"',
        'Roboto',
        '"Helvetica Neue"',
        'Arial',
        'sans-serif',
        '"Apple Color Emoji"',
        '"Segoe UI Emoji"',
        '"Segoe UI Symbol"'
      ].join(',')
    }
  });
};

export default themeWrapper;
