

import {
  SEND_FEEDBACK,
  SEND_FEEDBACK_SUCCEEDED,
  SEND_FEEDBACK_FAILED,
} from '../../store/constants/feedback';
import { LOGOUT } from '../../store/constants/authentication';

const initialState = {
  loading: false,
  error: {},
};

export default (state: typeof initialState = initialState, action: any) => {
  switch (action.type) {
    case LOGOUT:
      return initialState;
    case SEND_FEEDBACK:
      return {
        ...state,
        loading: true,
      };
    case SEND_FEEDBACK_SUCCEEDED:
      return {
        ...state,
        loading: false,
      };
    case SEND_FEEDBACK_FAILED:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    default:
      return state;
  }
};
