import {
  UPDATE_PROFILE,
  UPDATE_PROFILE_SUCCEEDED,
  UPDATE_PROFILE_FAILED,
  FETCH_PENDING_REVIEWS,
  FETCH_PENDING_REVIEWS_SUCCEEDED,
  FETCH_PENDING_REVIEWS_FAILED,
  ADD_REVIEW_CANDIDATE,
  ADD_REVIEW_CANDIDATE_SUCCEEDED,
  ADD_REVIEW_CANDIDATE_FAILED,
  FETCH_REVIEWS,
  FETCH_REVIEWS_SUCCEEDED,
  FETCH_REVIEWS_FAILED,
  UPDATE_IMAGE,
  UPDATE_IMAGE_SUCCEEDED,
  UPDATE_IMAGE_FAILED,
  FETCH_ACCOUNT_INFO,
  FETCH_ACCOUNT_INFO_SUCCEEDED,
  FETCH_ACCOUNT_INFO_FAILED,
  FETCH_PROFILE,
  FETCH_PROFILE_SUCCEEDED,
  FETCH_PROFILE_FAILED,
} from '../../store/constants/user';

export const fetchAccountInfo = () => ({
  type: FETCH_ACCOUNT_INFO,
});

export const fetchAccountInfoSucceeded = payload => ({
  type: FETCH_ACCOUNT_INFO_SUCCEEDED,
  payload,
});

export const fetchAccountInfoFailed = error => ({
  type: FETCH_ACCOUNT_INFO_FAILED,
  error,
});

export const fetchProfile = () => ({
  type: FETCH_PROFILE,
});

export const fetchProfileSucceeded = payload => ({
  type: FETCH_PROFILE_SUCCEEDED,
  payload,
});

export const fetchProfileFailed = error => ({
  type: FETCH_PROFILE_FAILED,
  error,
});

export const updateProfile = (user, path?, silent = false) => ({
  type: UPDATE_PROFILE,
  user,
  path,
  silent,
});

export const updateProfileSucceeded = (payload, imageType?) => ({
  type: UPDATE_PROFILE_SUCCEEDED,
  payload,
  imageType,
});

export const updateProfileFailed = error => ({
  type: UPDATE_PROFILE_FAILED,
  error,
});

export const fetchPendingReviews = () => ({
  type: FETCH_PENDING_REVIEWS,
});

export const fetchPendingReviewsSucceeded = payload => ({
  type: FETCH_PENDING_REVIEWS_SUCCEEDED,
  payload,
});

export const fetchPendingReviewsFailed = error => ({
  type: FETCH_PENDING_REVIEWS_FAILED,
  error,
});

export const addReviewCandidate = (id, data) => ({
  type: ADD_REVIEW_CANDIDATE,
  id,
  data,
});

export const addReviewCandidateSucceeded = (payload, applicationId) => ({
  type: ADD_REVIEW_CANDIDATE_SUCCEEDED,
  payload,
  applicationId,
});

export const addReviewCandidateFailed = error => ({
  type: ADD_REVIEW_CANDIDATE_FAILED,
  error,
});

export const fetchReviews = () => ({
  type: FETCH_REVIEWS,
});

export const fetchReviewsSucceeded = payload => ({
  type: FETCH_REVIEWS_SUCCEEDED,
  payload,
});

export const fetchReviewsFailed = error => ({
  type: FETCH_REVIEWS_FAILED,
  error,
});

export const updateImage = (image, imageType, third) => ({
  type: UPDATE_IMAGE,
  image,
  imageType,
  third,
});

export const updateImageSucceeded = (payload, imageType) => ({
  type: UPDATE_IMAGE_SUCCEEDED,
  payload,
  imageType,
});

export const updateImageFailed = error => ({
  type: UPDATE_IMAGE_FAILED,
  error,
});
