import React, { ComponentType } from "react";
import propTypes from "prop-types";
import Api from "quickjobs-api-wrapper";
import { FormattedMessage } from "react-intl";
import { compose } from "redux";
import { connect } from "react-redux";
import { push } from "connected-react-router";
import { withStyles, WithStyles } from "@material-ui/core/styles";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import PowerIcon from "@material-ui/icons/PowerSettingsNew";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { logout } from "../../store/actions/authentication";
import HandshakeIcon from '@material-ui/icons/People';
import styles from "./styles";

const mapDispatchToProps = dispatch => ({
  redirect: path => dispatch(push(path)),
  logoutFunc: () => dispatch(logout())
});
type DispatchProps = ReturnType<typeof mapDispatchToProps>;

type OwnProps = {
  anchorEl: React.ReactNode,
  onClose: any,
}

type Props = WithStyles<typeof styles> & DispatchProps & OwnProps;

const handleClose = (props: Props, path) => {
  props.onClose();
  props.redirect(path);
};

export const UserMenu = (props: Props) => {
  const handleLogout = path => {
    Api.restoreUserToken(null);
    props.onClose();
    props.redirect(path);
    props.logoutFunc();
  };




  return <Menu style={{ top: '60px' }} id="simple-menu" anchorEl={props.anchorEl as any} open={Boolean(props.anchorEl)} onClose={props.onClose}>
    <MenuItem onClick={() => {
return window.open(
  'https://www.quickjobs.cz/slevy-a-vyhody-pro-zamestnavatele',
  '_blank'
)
    }}>
      <HandshakeIcon className={props.classes.icon} />
      <FormattedMessage tagName="span" id="masterview.usermenu.partners" />
    </MenuItem>
      <MenuItem onClick={() => handleClose(props, '/my-profile')}>
        <FontAwesomeIcon icon={['far', 'user']} className={props.classes.icon} style={{ marginLeft: 2, marginRight: 14 }} size="lg" />
        <FormattedMessage tagName="span" id="masterview.usermenu.profile" />
      </MenuItem>
      <MenuItem onClick={() => handleLogout('/login')}>
        <PowerIcon className={props.classes.icon} />
        <FormattedMessage tagName="span" id="masterview.usermenu.logout" />
      </MenuItem>
    </Menu>;
};

UserMenu.propTypes = {
  anchorEl: propTypes.object, // eslint-disable-line
  onClose: propTypes.func.isRequired,
};

export default compose<ComponentType<OwnProps>>(connect(null, mapDispatchToProps), withStyles(styles))(UserMenu);