
export const UPDATE_JOB_OFFER_DRAFT = 'JOBS/UPDATE_JOB_OFFER_DRAFT' as const;

export const CREATE_OFFER = 'JOBS/CREATE_OFFER' as const;
export const CREATE_OFFER_SUCCEEDED = 'JOBS/CREATE_OFFER_SUCCEEDED' as const;
export const CREATE_OFFER_FAILED = 'JOBS/CREATE_OFFER_FAILED' as const;

export const GET_MY_JOBS = 'JOBS/GET_MY_JOBS' as const;
export const GET_MY_JOBS_SUCCEEDED = 'JOBS/GET_MY_JOBS_SUCCEEDED' as const;
export const GET_MY_JOBS_FAILED = 'JOBS/GET_MY_JOBS_FAILED' as const;

export const GET_JOB = 'JOBS/GET_JOB' as const;
export const GET_JOB_SUCCEEDED = 'JOBS/GET_JOB_SUCCEEDED' as const;
export const GET_JOB_FAILED = 'JOBS/GET_JOB_FAILED' as const;

export const CANCEL_JOB = 'JOBS/CANCEL_JOB' as const;
export const CANCEL_JOB_SUCCEEDED = 'JOBS/CANCEL_JOB_SUCCEEDED' as const;
export const CANCEL_JOB_FAILED = 'JOBS/CANCEL_JOB_FAILED' as const;

export const PUSH_NEW_STATS = 'JOBS/PUSH_NEW_STATS' as const;
export const PUSH_JOB_ENDED = 'JOBS/PUSH_JOB_ENDED' as const;
export const PUSH_JOB_BANNED = 'JOBS/PUSH_JOB_BANNED' as const;
