import { Theme } from "@material-ui/core";
import { StyleRules } from "@material-ui/core/styles";

export default (theme: Theme): StyleRules => ({
  logoWrap: {
    cursor: 'pointer',
  },
  spacer: {
    height: 128,
    width: '100%',
  },
  spacerProfile: {
    height: '80px !important',
  },
  appBar: {
    height: 128,
  },
  toolbar: {
    height: 72,
  },
  toolbarFlex: {
    flex: 1,
  },
  avatar: {
    marginLeft: 25,
    cursor: 'pointer',

    '& img': {
      backgroundColor: '#F0F3F4',
    },
  },
  headerUserWrap: {
    display: 'flex',
    alignItems: 'center',
    padding: '15px 0px',
    cursor: 'pointer',
  },
  userName: {
    color: theme.palette.background.default,
    fontFamily: 'Montserrat',
    fontSize: '14px',
    fontWeight: 300,
    textAlign: 'center',
    marginLeft: '16px',
    cursor: 'pointer',
  },
  availableJobsButton: {
    fontSize: 20,
    marginRight: 25,

    '& .dd': {
      color: theme.palette.secondary.main,
      fontSize: '19px',
      fontWeight: 500,
    },
  },
  secondAppBar: {
    height: 56,
    width: '100%',
  },
  secondToolbar: {
    height: 56,
    minHeight: 56,
  },
  customButtonLabel: {
    textTransform: 'none',
    fontWeight: 400,
  },
  buttonWrapper: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    '& button': {
      // marginRight: 5,
      overflow: 'visible',
    },
  },
  buttonIcon: {
    marginRight: 10,
  },
  buttonText: {
    fontWeight: 400,
    textTransform: 'inherit',
  },
  loaderWrap: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: 'calc(100vh - 128px)',
  },
  divider: {
    borderRight: '1px solid rgba(255,255,255,0.2)',
    height: '40px',
    margin: '0 10px',
  },
  availableJobOffers: {
    color: 'rgba(0,47,73,0.6)',
    fontFamily: 'Montserrat',
    fontSize: '16px',
    fontWeight: 500,
    marginLeft: '15px',

    '& .dd': {
      color: theme.palette.secondary.main,
    },
  },
  availableJobOffersLink: {
    color: theme.palette.secondary.main,
    fontFamily: 'Montserrat',
    fontSize: '14px',
    fontWeight: 500,
    cursor: 'pointer',
    border: 'none',
    backgroundColor: 'transparent',
    outline: 'none',
  },
  createOfferButton: {
    color: theme.palette.background.default,
    fontFamily: 'Montserrat',
    fontSize: '14px',
    fontWeight: 300,
    textAlign: 'center',
    boxShadow: 'none',
    borderRadius: '0',
  },
  showNoJobOffers: {
    height: '72px !important',
  },
  toolbarButton: {
    backgroundColor: 'transparent',
    border: 'none',
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row',
    textTransform: 'inherit',
    cursor: 'pointer',
    paddingLeft: '5px',
    paddingRight: '5px',

    '& svg': {
      color: 'rgba(0,47,73,0.6)',
      paddingTop: '3px',
    },
  },
  loadingSpinner: {
    display: 'inline-block',
    width: '21px',

    '& img': {
      maxWidth: '19px',
      position: 'absolute',
      left: '151px',
      bottom: '-15px',
    },
  },
});
