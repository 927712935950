import React from 'react';
import { createBrowserHistory } from 'history';
import _mapKeys from 'lodash/mapKeys';
import * as Sentry from '@sentry/browser';

import GoogleAnalytics from 'react-ga';

import { ConnectedRouter } from 'connected-react-router';

import MuiPickersUtilsProvider from 'material-ui-pickers/utils/MuiPickersUtilsProvider';
import MomentUtils from 'material-ui-pickers/utils/moment-utils';

import ThemeProvider from './components/ThemeProvider';
import ServerSentEvents from './components/ServerSentEvents';
import InitializeApi from './components/InitializeApi';

import env from './config';
import RouterInit from "./config/Router";
import StateInit from "./config/State";
import IntlInit from "./config/Intl";
import "./config/Icons";

const LANGUAGE = "cs";

if (env.GOOGLE_ANALYTICS_KEY) {
  GoogleAnalytics.initialize(env.GOOGLE_ANALYTICS_KEY);
  GoogleAnalytics.pageview(window.location.pathname + window.location.search);
  GoogleAnalytics.set({ anonymizeIp: true });
}

const history = createBrowserHistory();


if (process.env.NODE_ENV !== 'production') {
  const whyDidYouRender = require('@welldone-software/why-did-you-render/dist/no-classes-transpile/umd/whyDidYouRender.min.js');
  whyDidYouRender(React);
}

Sentry.init({ dsn: env.SENTRY_DSN });

class App extends React.Component<any, { error: any }> {
  state = { error: null };

  componentDidCatch(error, errorInfo) {
    this.setState({ error });
    Sentry.withScope(scope => {
      _mapKeys(errorInfo, key => scope.setExtra(key, errorInfo[key]));
      Sentry.captureException(error);
    });
  }

  public render() {
    const { error } = this.state;

    if (error) {
      return (
        <button onClick={() => Sentry.showReportDialog({ lang: LANGUAGE })}>
          Report feedback
        </button>
      );
    }

    return (
      <StateInit history={history}>
        <IntlInit language={LANGUAGE}>
          <ThemeProvider>
            <MuiPickersUtilsProvider utils={MomentUtils}>
              <InitializeApi config={env.API_CONFIG}>
                <ServerSentEvents.Provider>
                  <ConnectedRouter history={history}>
                    <RouterInit />
                  </ConnectedRouter>
                </ServerSentEvents.Provider>
              </InitializeApi>
            </MuiPickersUtilsProvider>
          </ThemeProvider>
        </IntlInit>
      </StateInit>
    );
  }
}

export default App;
