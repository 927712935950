import React from "react";
import { Button, Theme, WithStyles, withStyles } from "@material-ui/core";
import { StyleRules } from "@material-ui/core/styles";
import { CSSProperties } from "@material-ui/core/styles/withStyles";

interface OwnProps {
  children: React.ReactNode;
  corners?: "sharp" | "rounded";
  onClick?: (event: React.MouseEvent) => void;
}

const styles = (theme: Theme): StyleRules => {
  const def: CSSProperties = {
    color: theme.palette.background.default,
    fontFamily: 'Montserrat',
    fontSize: '14px',
    fontWeight: 300,
    textAlign: 'center',
    boxShadow: 'none',
    borderRadius: "0",
  };

  return {
    default: def,
    rounded: {
      ...def,
      borderRadius: "4px",
    },
  };
};

type Props = OwnProps & WithStyles<typeof styles>;

function PrimaryButton({ children, onClick, classes, corners = "sharp" }: Props) {
  return (
    <Button variant="raised" color="secondary" className={corners === "sharp" ? classes.default : classes.rounded} onClick={onClick}>
      {children}
    </Button>
  );
}

export default withStyles(styles)(PrimaryButton);