import { Theme } from "@material-ui/core";
import { StyleRules } from "@material-ui/core/styles";

export default (theme: Theme): StyleRules => ({
  root: {
    position: 'fixed',
    top: '0',
    left: '0',
    right: '0',
    bottom: '0',
    backgroundColor: theme.palette.primary.main,
    height: '100%',
    textAlign: 'center',
    color: theme.palette.background.default,
    oveflowY: 'scroll',
    fontFamily: 'Montserrat',

    '& h2': {
      color: theme.palette.background.default,
      fontSize: '24px',
      lineHeight: '32px',
      margin: '32px auto 0 auto',
      width: '327px',
    },

    '& h3': {
      color: theme.palette.background.default,
      fontSize: '18px',
      fontWeight: 500,
      lineHeight: '26px',
      margin: '0 auto 24px auto',
      width: '301px',

      '& a': {
        color: '#fff',
        textDecoration: 'none',
        fontWeight: 600,
      },
    },

    '& p': {
      opacity: '0.6',
      color: theme.palette.background.default,
      fontFamily: 'Montserrat',
      fontSize: '18px',
      lineHeight: '26px',
      marginBottom: '24px',

      '&:nth-child(7)': {
        opacity: '1',
      },
    },
  },
  divider: {
    backgroundColor: theme.palette.secondary.main,
    height: '2px',
    width: '40px',
    margin: '24px auto',
  },
  download: {
    display: 'flex',
    margin: '0 auto 24px',
    width: '295px',
    justifyContent: 'space-around',

    '& img': {
      cursor: 'pointer',
    },
  },
});
