
import immutable from 'immer';
import {
  SEND_INQUIRY,
  SEND_INQUIRY_SUCCEEDED,
  SEND_INQUIRY_FAILED,
  FETCH_SERVICE_INFO,
  FETCH_SERVICE_INFO_SUCCEEDED,
  FETCH_SERVICE_INFO_FAILED,
} from '../../store/constants/service';

import { ACTIONS } from '../actions/service';
import { ServiceState } from '../types/service';

const initialState: ServiceState = {
  plans: [],
  packages: [],
  jobApplicationConfig: null,
  jobOfferConfig: null,
  basicPlan: null,
  contacts: null,
  calls: {
    sendInquiry: {
      isLoading: false,
      issuedAt: null,
      error: null,
    },
    fetchInfo: {
      isLoading: false,
      issuedAt: null,
      error: null,
    },
  },
};

export default (
  state: ServiceState = initialState,
  action: ACTIONS,
): ServiceState =>
  immutable(state, draft => {
    switch (action.type) {
      case SEND_INQUIRY:
        draft.calls.sendInquiry = {
          isLoading: true,
          issuedAt: Date.now(),
          error: null,
        };
        break;
      case SEND_INQUIRY_SUCCEEDED:
        draft.calls.sendInquiry.isLoading = false;
        break;
      case SEND_INQUIRY_FAILED:
        draft.calls.sendInquiry.isLoading = false;
        draft.calls.sendInquiry.error = action.error;
        break;
      case FETCH_SERVICE_INFO:
        draft.calls.fetchInfo = {
          isLoading: true,
          issuedAt: new Date().getTime(),
          error: null,
        };
        break;
      case FETCH_SERVICE_INFO_SUCCEEDED:
        draft.calls.fetchInfo.isLoading = false;
        draft.plans = action.payload.plans;
        draft.packages = action.payload.packages;
        draft.jobApplicationConfig = action.payload.jobApplicationConfig;
        draft.jobOfferConfig = action.payload.jobOfferConfig;
        draft.basicPlan = action.payload.basicPlan;
        draft.contacts = action.payload.contacts;
        break;
      case FETCH_SERVICE_INFO_FAILED:
        draft.calls.fetchInfo.isLoading = false;
        draft.calls.fetchInfo.error = action.error;
        break;
    }
    return draft;
  });
