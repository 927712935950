import { library as fontIconsLibrary } from '@fortawesome/fontawesome-svg-core';
import { faLightbulb, faQuestionCircle, faUser } from '@fortawesome/free-regular-svg-icons';
import {
  faFileAlt,
  faHandHoldingUsd,
  faUsers,
  faCalendar,
  faMapMarkerAlt
} from '@fortawesome/free-solid-svg-icons';

fontIconsLibrary.add(
  faLightbulb,
  faQuestionCircle,
  faUser,
  faFileAlt,
  faHandHoldingUsd,
  faUsers,
  faCalendar,
  faMapMarkerAlt
);
