import { JOB_TERM } from "quickjobs-api-wrapper";

function onTermCondition <O, L, F>(term: JOB_TERM, oneTime: O, longTerm: L, fullTime: F): O | L | F
function onTermCondition <O, L, F, D>(term: JOB_TERM, oneTime: O, longTerm: L, fullTime: F, def: D): O | L | F | D
function onTermCondition <O, L, F, D>(term: JOB_TERM, oneTime: O, longTerm: L, fullTime: F, def?: D) {
  if (term === JOB_TERM.ONE_TIME) {
    return oneTime;
  } else if (term === JOB_TERM.LONG_TERM) {
    return longTerm;
  } else if (term === JOB_TERM.FULL_TIME) {
    return fullTime;
  } else if (def) {
    return def;
  }
  throw new Error(`Invalid term ${term}.`);
}

export {onTermCondition};
