export const QUICKJOBS_BUSINESS_RULES_WEBSITE_LINK =
  'https://quickjobs.cz/obchodni-podminky';

export const QUICKJOBS_PRIVACY_POLICY_WEBSITE_LINK =
  'https://quickjobs.cz/ochrana-osobnich-udaju';

export const QUICKJOBS_EMAIL = 'info@quickjobs.cz';
export const QUICKJOBS_PHONE = '+420 773 234 091';

export const QUICKJOBS_WEP_APP_LINK = 'https://app.quickjobs.cz/';

export const QUICKJOBS_IOS_APP_LINK =
  'https://itunes.apple.com/cz/app/quickjobs-cz/id1116612770?mt=8';
export const QUICKJOBS_ANDROID_APP_LINK =
  'https://play.google.com/store/apps/details?id=cz.quickjobs.midgard&hl=nl';
export const QUICKJOBS_MOBILE_APP_LINK =
  'https://u5dt4.app.goo.gl/Lr7u';

export const EVENT_NEW_APPLICATION = 'v2.new_application';
export const EVENT_JOB_AUTHORED = 'v2.job_authored';
export const EVENT_JOB_STATS = 'v2.job_stats';
export const EVENT_JOB_ENDED = 'v2.job_ended';
export const EVENT_JOB_BANNED = 'v2.job_banned';
export const EVENT_ACCOUNT_UPDATED = 'account_updated';
