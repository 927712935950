import React from "react";
import { Button, withStyles, Theme, WithStyles, Badge } from "@material-ui/core";
import Classnames from "classnames";
import { StyleRules } from "@material-ui/core/styles";

interface OwnProps {
  children: React.ReactNode;
  badge?: number;
  isActive?: boolean;
  onClick?: (event: React.MouseEvent) => void;
}

const styles = (theme: Theme): StyleRules => ({
  buttonStyles: {
    color: theme.palette.background.default,
    fontFamily: 'Montserrat',
    fontSize: '14px',
    textTransform: 'inherit',
    fontWeight: 300,
  },
  buttonActive: {
    backgroundColor: 'rgba(255,255,255,0.1)',
    color: theme.palette.secondary.main,
  },
  buttonWithBadge: {
    paddingRight: '32px',
  },
  customButtonBadge: {
    top: -2,
    right: -30,
  },
});

type Props = OwnProps & WithStyles<typeof styles>;

function MenuItem({ children, isActive, onClick, classes, badge }: Props) {
  return (
    <Button color="secondary" className={Classnames(classes.buttonStyles, {
      [classes.buttonWithBadge]: !!badge,
      [classes.buttonActive]: isActive,
    })} onClick={onClick}>
      {badge ? <Badge badgeContent={badge} color="secondary" classes={{ badge: classes.customButtonBadge }}>
        {children}
      </Badge> : children}
    </Button>
  );
}

export default withStyles(styles)(MenuItem);
