

import { createSelector } from 'reselect';

const selector = state => state.authentication;

// $FlowFixMe
export const verification = createSelector(
  selector,
  app => app.verification,
);
// $FlowFixMe
export const login = createSelector(
  selector,
  app => app.login,
);
// $FlowFixMe
export const phoneNumber = createSelector(
  selector,
  app => app.phoneNumber,
);
// $FlowFixMe
export const oauth = createSelector(
  selector,
  app => app.oauth,
);
// $FlowFixMe
export const fcmToken = createSelector(
  selector,
  app => app.fcmToken,
);
