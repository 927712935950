import {
  SEND_FEEDBACK,
  SEND_FEEDBACK_SUCCEEDED,
  SEND_FEEDBACK_FAILED,
} from '../constants/feedback';

export const sendFeedback = feedbackInput => ({
  type: SEND_FEEDBACK,
  feedbackInput,
});

export const sendFeedbackSucceeded = () => ({
  type: SEND_FEEDBACK_SUCCEEDED,
});

export const sendFeedbackFailed = error => ({
  type: SEND_FEEDBACK_FAILED,
  error,
});
