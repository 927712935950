

import immutable from 'immer';

import { PUSH, CLOSE, PROCESS_QUEUE } from '../../store/constants/notification';

const initialState = {
  open: false,
  queue: [] as any[],
  messageInfo: {} as any,
};

export default (state: typeof initialState = initialState, action: any) =>
  immutable(state, draft => {
    switch (action.type) {
      case PUSH:
        draft.queue.push({
          ...action,
          key: new Date().getTime(),
        });
        break;
      case CLOSE:
        draft.open = false;
        break;
      case PROCESS_QUEUE:
        if (draft.queue.length > 0) {
          draft.messageInfo = draft.queue.shift();
          draft.open = true;
        }
        break;
    }
    return draft;
  });
