/* eslint-disable indent */
import React from 'react';
import propTypes from 'prop-types';
import _get from 'lodash/get';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import { createStructuredSelector } from 'reselect';
import { FormattedMessage } from 'react-intl';
import Button from '@material-ui/core/Button';
import Snackbar from '@material-ui/core/Snackbar';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';

import { open, messageInfo } from '../../store/selectors/notification';
import { close, processQueue } from '../../store/actions/notification';

const mapStateToProps = createStructuredSelector({
  open,
  messageInfo
});

const mapDispatchToProps = (dispatch) => ({
  close: () => dispatch(close()),
  redirect: (path, state?) => dispatch(push(path, state)),
  processQueue: () => dispatch(processQueue())
});

const handleClose = (props, event, reason?) => {
  if (reason !== 'clickaway') {
    props.close();
  }
};

const ConsecutiveSnackbars = (props) => {
  const action = [] as any[];

  const actionCallback =
    _get(props.messageInfo.actionCallback, 'type') === 'redirect'
      ? () => {
          props.messageInfo.actionCallback.additionalFunction();
          props.redirect(
            props.messageInfo.actionCallback.path,
            props.messageInfo.actionCallback.state
          );
        }
      : props.messageInfo.actionCallback;

  if (
    props.messageInfo.actionMessage !== undefined &&
    props.messageInfo.actionCallback !== undefined
  ) {
    action.push(
      <Button key="undo" color="secondary" size="small" onClick={() => actionCallback()}>
        <FormattedMessage tagName="span" id={props.messageInfo.actionMessage} />
      </Button>
    );
  } else {
    action.push(
      <IconButton
        key="close"
        aria-label="Close"
        color="inherit"
        onClick={(event, reason?) => {
          handleClose(props, event, reason);
        }}
      >
        <CloseIcon />
      </IconButton>
    );
  }

  return (
    <Snackbar
      key={props.messageInfo.key}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left'
      }}
      open={props.open}
      autoHideDuration={6000}
      onClose={(event, reason) => handleClose(props, event, reason)}
      onExited={() => props.processQueue()}
      ContentProps={{
        'aria-describedby': 'message-id'
      }}
      message={
        <span id="message-id">
          {props.messageInfo.message !== 'Failed to fetch'
            ? props.messageInfo.message
            : 'Přihlášeno'}
        </span>
      }
      action={action}
    />
  );
};

ConsecutiveSnackbars.propTypes = {
  open: propTypes.bool.isRequired,
  redirect: propTypes.func.isRequired,
  messageInfo: propTypes.shape({
    message: propTypes.string,
    actionMessage: propTypes.string,
    actionCallback: propTypes.func,
    key: propTypes.number
  }).isRequired,
  close: propTypes.func.isRequired, // eslint-disable-line
  processQueue: propTypes.func.isRequired
};

export default compose(connect(mapStateToProps, mapDispatchToProps))(ConsecutiveSnackbars);
