/* eslint-disable no-template-curly-in-string */
export const mixed = {
  default: 'Neplatné',
  required: 'Povinné',
  oneOf: 'Musí být jednou z následujících hodnot: ${values}',
  notOneOf: 'Nesmí být jednou z následujících hodnot: ${values}',
};

export const string = {
  length: 'Musí mít přesně ${length} znaků',
  min: 'Musí mít alespoň ${min} znaků',
  max: 'Nesmí mít více jak ${max} znaků',
  matches: 'Musí se shodovat s výrazem: "${regex}"',
  email: 'Musí být platnou emailovou adresou',
  url: 'Musí být platnou URL adresou',
  trim: 'Nesmí obsahovat znaky navíc',
  lowercase: 'Musí obsahovat pouze malá písmena',
  uppercase: 'Musí obsahovat pouze velká písmena',
};

export const number = {
  min: 'Musí být větší nebo rovno hodnotě ${min}',
  max: 'Musí být menší nebo rovno hodnotě ${max}',
  lessThan: 'Musí být méně než ${less}',
  moreThan: 'Musí být více než ${more}',
  notEqual: 'Nesmí být rovno ${notEqual}',
  positive: 'Musí být kladným číslem',
  negative: 'Musí být záporným číslem',
  integer: 'Musí být celé číslo',
};

export const date = {
  min: 'Musí být později než ${min}',
  max: 'Musí být dříve než ${max}',
};

export const boolean = {};

export const object = {
  noUnknown: 'Nesmí obsahovat neznámé parametry',
};

export const array = {
  min: 'Musí obsahovat alespoň ${min} položek',
  max: 'Musí obsahovat méně ${max} položek',
};

export default {
  mixed,
  string,
  number,
  date,
  object,
  array,
  boolean,
};
