import Loadable from 'react-loadable';
import LoadingSpinnerScreen from '../../components/LoadingSpinnerScreen';

/*
  Has to be loaded like this otherwise form validation translations won't be applied
*/
export default Loadable({
  loader: () => import('./modal'),
  loading: LoadingSpinnerScreen,
});
