
import Immutable from 'immutable';
import Serialize from 'remotedev-serialize';

export default (key: string) => (state: any) => {
  if (key === 'authentication') {
    const serializer = Serialize.immutable(Immutable);
    return serializer.parse(state.authentication).toJS();
  }
  return state;
};
