import React from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { createStructuredSelector } from 'reselect';
import Api, { TokenPair } from 'quickjobs-api-wrapper';

import { oauth } from '../../store/selectors/authentication';
import { loginSucceeded } from '../../store/actions/authentication';
import { fetchServiceInfo } from '../../store/actions/service';
import { isServiceInfoLoaded } from '../../store/selectors';
import LoadingSpinnerScreen from '../LoadingSpinnerScreen';

type OwnProps = {
  children: React.ReactNode;
  config: Parameters<typeof Api.configure>[0];
};

const mapStateToProps = createStructuredSelector({
  authData: oauth,
  isLoaded: isServiceInfoLoaded
});
type StateProps = ReturnType<typeof mapStateToProps>;

const mapDispatchToProps = (dispatch: Dispatch) => ({
  onLoginSuccess: (data: TokenPair) => dispatch(loginSucceeded({ oauth: data })),
  fetchServiceInfo: () => dispatch(fetchServiceInfo())
});
type DispatchProps = ReturnType<typeof mapDispatchToProps>;

type Props = OwnProps & StateProps & DispatchProps;

class InitializeApi extends React.Component<Props> {
  UNSAFE_componentWillMount() {
    const { authData, onLoginSuccess, config } = this.props;
    Api.configure(config);
    this.props.fetchServiceInfo();
    Api.onUserTokenRefresh = token => {
      onLoginSuccess(token);
    };
    Api.restoreUserToken(authData);
    // eslint-disable-next-line no-console
    console.info('[API] Connection established. ');
  }

  componentWillUnmount() {
    // eslint-disable-next-line no-console
    console.info('[API] Connection closed');
  }

  render() {
    if (!this.props.isLoaded) {
      return <LoadingSpinnerScreen />;
    }
    return this.props.children;
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(InitializeApi);
