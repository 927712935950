import React, { ComponentType } from 'react';
import { FormattedMessage } from 'react-intl';
import { compose, Dispatch } from 'redux';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import { withStyles, WithStyles } from '@material-ui/core/styles';
import Popover from '@material-ui/core/Popover';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import GiftIcon from '@material-ui/icons/CardGiftcard';
import { ServiceState } from '../../store/types/service';
import { intl } from '../../utils/globalIntl';
import PackageCard from '../../components/PackageCard';
import Moment from 'moment';
import _find from 'lodash/find';
import styles from './styles';
import * as routes from '../../routes';
import { JOB_TERM } from 'quickjobs-api-wrapper';
import { onTermCondition } from '../../utils/jobTerm';
import { Typography } from '@material-ui/core';

type OwnProps = {
  open: boolean;
  anchorEl: any;
  onClose: any;
  id: string;
  jobTerm: JOB_TERM;
};

const mapDispatchToProps = (dispatch: Dispatch) => ({
  redirect: (path, state?) => dispatch(push(path, state))
});
type DispatchProps = ReturnType<typeof mapDispatchToProps>;

const mapStateToProps = (state: { service: ServiceState; user: any }) => {
  const account = state.user.account;

  return {
    totalAvailableCredits: account?.totalJobCredits || { oneTime: 0, longTerm: 0, fullTime: 0 },
    service: account && account.service,
    userBasicPlan: account && account.userBasicPlan,
    userPackages: account && account.userPackages,
    userPlan: account && account.userPlan,
    userPlans: account && account.userPlans
  };
};
type StateProps = ReturnType<typeof mapStateToProps>;

type Props = OwnProps & WithStyles<typeof styles> & StateProps & DispatchProps;

const OffersPopper = (props: Props) => {
  const shouldDisplay = (data, jobTerm: JOB_TERM) => {
    if (jobTerm === JOB_TERM.ONE_TIME && data.jobCredits.oneTime) return true;
    if (jobTerm === JOB_TERM.LONG_TERM && data.jobCredits.longTerm) return true;
    if (jobTerm === JOB_TERM.FULL_TIME && data.jobCredits.fullTime) return true;
    return false;
  };

  const { userBasicPlan, jobTerm, service, userPlans } = props;

  const creditField = onTermCondition(jobTerm, 'oneTime', 'longTerm', 'fullTime');

  let items: React.ReactNodeArray = [];
  if (props.userBasicPlan && service.basicPlan.jobCredits[creditField] > 0) {
    items.push(
      <div>
        <PackageCard
          available={userBasicPlan.jobCredits[creditField]}
          icon={<GiftIcon style={{ fontSize: 30 }} />}
          title={intl.formatMessage({ id: 'packageCard.info.free.ads' })}
          endsAt={Moment(props.userBasicPlan.endsAt).format('D. M. YYYY')}
          totalAvailable={service.basicPlan.jobCredits[creditField]}
        />
        <br />
      </div>
    );
  }

  items.push(
    ...(props.userPackages || []).map(userPackage => {
      if (!shouldDisplay(userPackage, jobTerm)) return null;
      const packagePrototype = _find(props.service.packages, ['id', userPackage.packageId]);
      const available = userPackage.jobCredits[creditField];
      const total = packagePrototype.jobCredits[creditField];
      let title: string;
      if (total === 1) {
        title = intl.formatMessage({
          id: 'pricing.subscription.pricing.garantee'
        });
      } else {
        title = intl.formatMessage(
          {
            id: 'packageCard.active.packages'
          },
          { total }
        );
      }
      return (
        <div key={userPackage.id}>
          <PackageCard
            available={available}
            icon={<GiftIcon style={{ fontSize: 30 }} />}
            title={title}
            endsAt={Moment(userPackage.endsAt).format('D. M. YYYY')}
            totalAvailable={total}
          />
          <br />
        </div>
      );
    })
  );

  items.push(
    ...(userPlans || []).map(plan => {
      if (!shouldDisplay(plan, jobTerm)) return null;
      const planPrototype = _find(service.plans, ['id', plan.planId]);
      return (
        <PackageCard
          key={plan.id}
          available={plan.jobCredits[creditField]}
          icon={<GiftIcon style={{ fontSize: 30 }} />}
          title={intl.formatMessage({
            id: planPrototype.codeName
          })}
          endsAt={Moment(plan.endsAt).format('D. M. YYYY')}
          autoRenew={plan.autoRenew}
          expirationUnit={planPrototype.expirationUnit}
          jobCreditRenewAt={Moment(plan.jobCreditRenewAt).format('D. M. YYYY')}
          totalAvailable={planPrototype.jobCredits[creditField]}
        />
      );
    })
  );

  items = items.filter(item => !!item);

  return (
    <Popover
      id={props.id}
      open={props.open}
      anchorEl={props.anchorEl}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right'
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right'
      }}
      onClose={props.onClose}
    >
      <Paper className={props.classes.root} square>
        {items.length === 0 && (
          <Typography align="center" color="textSecondary">
            <FormattedMessage id="packageCard.info.empty" />
          </Typography>
        )}
        {items}
        <div style={{ height: '-webkit-calc(17px + 53px) !important' }} />
        <br />
        <br />
        <br />
        <br />
        <br />
        <div className={props.classes.sticky}>
          <Button
            className={props.classes.button}
            color="secondary"
            variant="raised"
            fullWidth
            onClick={() => props.redirect(routes.PRICING.build({}), { jobTerm })}
          >
            <FormattedMessage tagName="span" id="masterview.packages.getMore" />
          </Button>
        </div>
      </Paper>
    </Popover>
  );
};

export default compose<ComponentType<OwnProps>>(
  withStyles(styles),
  connect(mapStateToProps, mapDispatchToProps)
)(OffersPopper);
