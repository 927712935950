

import { push } from "connected-react-router";
import {
  SWITCH_THEME,
} from '../../store/constants/app';
import { RouteBuild } from "../../routes/builder";

export const switchTheme = () => ({
  type: SWITCH_THEME,
});
type TYPE_SWITCH_THEME = ReturnType<typeof switchTheme>;

export const openNewOfferModal = (withinRoute: RouteBuild, initialData?: any) => {
  return push({
    ...withinRoute,
    state: {
      ...withinRoute.state,
      initialFormData: initialData,
    },
    hash: "create",
  });
};

export const closeNewOfferModal = (withinRoute: RouteBuild) => {
  return push({
    ...withinRoute,
    hash: "",
  });
};

export type ACTIONS =
  | TYPE_SWITCH_THEME;
