

/* eslint-disable react/require-default-props */

import React from "react";

import { FormattedMessage } from "react-intl";

import { withStyles, WithStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import classnames from "classnames";
import styles from "./styles";

type Props = WithStyles<typeof styles> & {
  available: number;
  totalAvailable: number;
  expirationUnit?: string;
  jobCreditRenewAt?: string;
  icon: any;
  title: string | any;
  endsAt: string;
  autoRenew?: boolean;
};

const PackageCard = (props: Props) => <Paper className={classnames(props.classes.paper, {
  [props.classes.noAvailableOffers]: props.available === 0
})}>
    <div className={props.classes.mainBox}>
      <div className={props.classes.iconWrapper}>{props.icon}</div>
      <div className={props.classes.textWrapper}>
        <Typography variant="body2" className={props.classes.title}>
          {props.title}
        </Typography>
        {props.expirationUnit && props.expirationUnit === 'month' && (props.autoRenew ? <Typography variant="caption">
                <FormattedMessage tagName="span" id="packageCard.month.renewal" />
                <br />
                <FormattedMessage tagName="span" id="packageCard.renewal.on.date" />
                &nbsp;
                {props.jobCreditRenewAt}
              </Typography> : <Typography variant="caption">
                <FormattedMessage tagName="span" id="packageCard.expires" />
                {props.endsAt}
              </Typography>)}

        {props.expirationUnit && props.expirationUnit === 'year' && (props.autoRenew ? <Typography variant="caption">
                <FormattedMessage tagName="span" id="packageCard.year.renewal" />
                <br />
                <FormattedMessage tagName="span" id="packageCard.year.title" />
                &nbsp;
                {props.jobCreditRenewAt}
                <br />
                <FormattedMessage tagName="span" id="packageCard.renewal.on.date" />
                &nbsp;
                {props.endsAt}
              </Typography> : <Typography variant="caption">
                <FormattedMessage tagName="span" id="packageCard.year.renewal" />
                <br />
                <FormattedMessage tagName="span" id="packageCard.renewal.off.year" />
                &nbsp;
                {props.endsAt}
              </Typography>)}

        {!props.expirationUnit && <Typography variant="caption">
            <FormattedMessage tagName="span" id="packageCard.expires" />
            {props.endsAt}
          </Typography>}
      </div>
    </div>
    <div className={props.classes.colorBox}>
      <Typography variant="caption" className={props.classes.caption}>
        <FormattedMessage tagName="span" id="packageCard.available" />
      </Typography>
      <Typography className={props.classes.offers} variant="subheading" color="secondary">
        {props.available === -1 ? <i className="material-icons">all_inclusive</i> : <span>
            {props.available}
            <i>
              /&nbsp;
              {props.totalAvailable}
            </i>
          </span>}
      </Typography>
    </div>
  </Paper>;

PackageCard.defaultProps = {
  jobCreditRenewAt: '',
  autoRenew: false
};

export default withStyles(styles)(PackageCard);