import React from 'react';
import { createIntlCache, createIntl, RawIntlProvider, IntlShape } from 'react-intl';
import { setLocale as setValidatorLocale } from 'yup';
import moment from 'moment';
import 'moment/locale/cs';

import IntlGlobalProvider from '../utils/globalIntl';
import messagesEn from '../translations/messages/en.json';
import messagesCs from '../translations/messages/cs.json';
import validationCs from '../translations/validation/cs';

const messages = {
  cs: messagesCs,
  en: messagesEn
};

const validations = {
  cs: validationCs
};

interface Props {
  language: keyof typeof messages;
  children: React.ReactNode;
}

export default function IntlInit(props: Props) {
  const language = props.language;
  // This is optional but highly recommended
  // since it prevents memory leak
  const cache = createIntlCache();
  const intl = createIntl(
    {
      locale: language,
      messages: messages[language] || messagesEn
    },
    cache
  );

  const enhancedIntl: IntlShape = {
    ...intl,
    formatMessage: (descriptor, values?) =>
      intl.formatMessage(descriptor, {
        strong: (...str) => <strong>{str}</strong>,
        ...values
      }),
  };

  setValidatorLocale(validations[language]);

  moment.locale(language);

  return (
    <RawIntlProvider value={enhancedIntl}>
      <IntlGlobalProvider intl={enhancedIntl}>{props.children}</IntlGlobalProvider>
    </RawIntlProvider>
  );
}
