import { StyleRules } from "@material-ui/core/styles";

export default (): StyleRules => ({
  root: {
    width: 380,
    zIndex: 2000,
    padding: 40,
    paddingBottom: 0,
    boxShadow: 'none',
    maxHeight: '300px',
  },
  button: {
    boxShadow: 'none',
    marginBottom: '17px',
    maxWidth: '380px',
  },
  sticky: {
    position: 'sticky',
    textAlign: 'center',
    bottom: '0',
    marginLeft: '-40px',
    width: '460px',
    paddingTop: '14px',
    backgroundColor: '#fff',
    boxShadow: '0 -3px 8px 0 rgba(0,0,0,0.1)',
    marginTop: '-64px',
  },
});
