

import { takeLatest, all, put } from 'redux-saga/effects';
import Api from 'quickjobs-api-wrapper';

import { push } from '../../store/actions/notification';

import { SEND_FEEDBACK } from '../../store/constants/feedback';

import {
  sendFeedbackSucceeded,
  sendFeedbackFailed,
} from '../../store/actions/feedback';

function* sendFeedbackAsync({ feedbackInput }: any) {
  try {
    yield Api.feedback.sendFeedback(feedbackInput);
    yield put(sendFeedbackSucceeded());
  } catch (ex) {
    yield put(sendFeedbackFailed(ex));
    yield put(push(ex.message));
  }
}

// $FlowFixMe
export default function* feedbackWatcher() {
  yield all([takeLatest(SEND_FEEDBACK, sendFeedbackAsync)]);
}
