import routeBuilder from './builder';
import { JOB_TERM } from 'quickjobs-api-wrapper';

export const HOME = routeBuilder.create({
  name: 'HOME',
  path: '/',
  exact: true
});

export const FULL_TIME_OFFER_DETAIL = routeBuilder.create<{ id: number }>({
  name: 'FULL_TIME_OFFER_DETAIL',
  path: ['/zamestnani/:id'],
  exact: true,
  groupId: JOB_TERM.FULL_TIME,
  component: () => import('../containers/JobOffers/FullTime')
});

export const FULL_TIME_OFFERS = routeBuilder.create<{}, {}, 'create'>({
  name: 'FULL_TIME_OFFERS',
  path: ['/zamestnani'],
  exact: true,
  groupId: JOB_TERM.FULL_TIME,
  component: () => import('../containers/JobOffers/FullTime')
});

export const LONG_TERM_OFFER_DETAIL = routeBuilder.create<{ id: number }>({
  name: 'LONG_TERM_OFFER_DETAIL',
  path: ['/longtime-offers/:id', '/dlouhodobe-brigady/:id'],
  exact: true,
  groupId: JOB_TERM.LONG_TERM,
  component: () => import('../containers/JobOffers/LongTerm')
});

export const LONG_TERM_OFFERS = routeBuilder.create<{}, {}, 'create'>({
  name: 'LONG_TERM_OFFERS',
  path: ['/longtime-offers', '/dlouhodobe-brigady'],
  exact: true,
  groupId: JOB_TERM.LONG_TERM,
  component: () => import('../containers/JobOffers/LongTerm')
});

export const ONE_TIME_OFFER_DETAIL = routeBuilder.create<{ id: number }>({
  name: 'ONE_TIME_OFFER_DETAIL',
  path: ['/onetime-offers/:id', '/jednorazove-brigady/:id'],
  exact: true,
  groupId: JOB_TERM.ONE_TIME,
  component: () => import('../containers/JobOffers/OneTime')
});

export const ONE_TIME_OFFERS = routeBuilder.create<{}, {}, 'create'>({
  name: 'ONE_TIME_OFFERS',
  path: ['/onetime-offers', '/jednorazove-brigady'],
  exact: true,
  groupId: JOB_TERM.ONE_TIME,
  component: () => import('../containers/JobOffers/OneTime')
});

export const HOW_IT_WORKS = routeBuilder.create({
  name: 'HOW_IT_WORKS',
  path: '/',
  exact: true,
  component: () => import('../containers/HowItWorks')
});

export const REVIEWS = routeBuilder.create({
  name: 'REVIEWS',
  path: '/reviews',
  exact: true,
  component: () => import('../containers/Reviews')
});

export const MY_PROFILE = routeBuilder.create({
  name: 'MY_PROFILE',
  path: '/my-profile',
  exact: true,
  component: () => import('../containers/MyProfile')
});

export const PRICING = routeBuilder.create<{}, { jobTerm: JOB_TERM }>({
  name: 'PRICING',
  path: ['/pricing', '/cenik'],
  exact: true,
  component: () => import('../containers/Pricing')
});

export default routeBuilder.collection([
  FULL_TIME_OFFER_DETAIL,
  FULL_TIME_OFFERS,
  LONG_TERM_OFFER_DETAIL,
  LONG_TERM_OFFERS,
  ONE_TIME_OFFER_DETAIL,
  ONE_TIME_OFFERS,
  HOW_IT_WORKS,
  REVIEWS,
  MY_PROFILE,
  PRICING
]);
