export default (key: string) => (state: any) => {
  if (key === "applications") {
    Object.values(state.byId).forEach((app: any) => {
      if (app.employerStatement) {
        return;
      }
      if (app.status === 'accepted') {
        app.employerStatement = 'saved';
      } else if (app.status === 'rejected') {
        app.employerStatement = 'rejected';
      }
    });
  }
  return state;
};
