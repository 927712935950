// eslint-disable-next-line @typescript-eslint/no-unused-vars
import React from "react";
import { IntlShape, MessageDescriptor } from "react-intl";
import { JOB_TERM } from "quickjobs-api-wrapper";
import { onTermCondition } from "./jobTerm";

// ======================================================
// React intl passes the messages and format functions down the component
// tree using the 'context' scope. the injectIntl HOC basically takes these out
// of the context and injects them into the props of the component. To be able to
// import this translation functionality as a module anywhere (and not just inside react components),
// this function inherits props & context from its parent and exports a singleton that'll
// expose all that shizzle.
// ======================================================
let INTL: IntlShape;
const IntlGlobalProvider = (props: { intl: IntlShape, children: any }) => {
  INTL = props.intl;
  return props.children;
};

// ======================================================
// Class that exposes translations
// ======================================================
let instance;
class IntlTranslator {
  // Singleton
  constructor() {
    if (!instance) {
      instance = this;
    }
    return instance;
  }

  // ------------------------------------
  // Formatting Functions
  // ------------------------------------
  formatMessage(message: MessageDescriptor, values?) {
    return INTL?.formatMessage(message, values) ?? message?.id;
  }
}

export const intl = new IntlTranslator();
export default IntlGlobalProvider;

export const jobTermSuffix = (term: JOB_TERM, path: string) => {
  const suffix = onTermCondition(term, "oneTime", "longTerm", "fullTime");
  return `${path}.${suffix}`;
};
