
import {
  SEND_VERIFICATION,
  SEND_VERIFICATION_SUCCEEDED,
  SEND_VERIFICATION_FAILED,
  LOGIN,
  LOGIN_SUCCEEDED,
  LOGIN_FAILED,
  LOGOUT,
  REQUEST_FCM_TOKEN_SUCCEEDED,
} from '../../store/constants/authentication';

const initialState = {
  verification: {
    userExists: false,
    loading: false,
    error: {},
  },
  phoneNumber: '',
  login: {
    loading: false,
    error: {},
  },
  oauth: {},
  fcmToken: '',
};

export default (state: typeof initialState = initialState, action: any) => {
  switch (action.type) {
    case SEND_VERIFICATION:
      return {
        ...state,
        verification: {
          ...state.verification,
          loading: true,
        },
        phoneNumber: action.phoneNumber,
      };
    case SEND_VERIFICATION_FAILED:
      return {
        ...state,
        verification: {
          ...state.verification,
          loading: false,
          error: action.error,
        },
      };
    case SEND_VERIFICATION_SUCCEEDED:
      return {
        ...state,
        verification: {
          ...state.verification,
          loading: false,
          userExists: action.payload.userExists,
        },
      };
    case LOGIN:
      return {
        ...state,
        login: {
          ...state.login,
          loading: true,
        },
      };
    case LOGIN_FAILED:
      return {
        ...state,
        login: {
          ...state.login,
          loading: false,
          error: action.error,
        },
        oauth: {},
      };
    case LOGIN_SUCCEEDED:
      return {
        ...state,
        login: {
          ...state.login,
          loading: false,
        },
        oauth: action.payload.oauth,
      };
    case LOGOUT:
      return initialState;
    case REQUEST_FCM_TOKEN_SUCCEEDED:
      return {
        ...state,
        fcmToken: action.payload.token,
      };

    default:
      return state;
  }
};
