import React, { Fragment } from 'react';
import { withStyles, WithStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import DownIcon from '@material-ui/icons/KeyboardArrowDown';
import LoadingSpinnerScreen from '../../components/LoadingSpinnerScreen';
import Toolbar from '@material-ui/core/Toolbar';
import { FormattedMessage } from 'react-intl';
import _keys from 'lodash/keys';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { push } from 'connected-react-router';
import { calcCredits } from '../../utils/pricing';
import { AppState } from '../../store/types/app';
import styles from './styles';
import PrimaryButton from '../buttons/PrimaryButton';
import * as routes from '../../routes';
import { JOB_TERM, AccountInfo } from 'quickjobs-api-wrapper';
import { jobTermSuffix } from '../../utils/globalIntl';
import idea from '../../assets/img/idea.png';

const mapStateToProps = (state: { app: AppState; user: any }) => ({
  account: state.user.account as AccountInfo
});
type StateProps = ReturnType<typeof mapStateToProps>;

const mapDispatchToProps = (dispatch: Dispatch) => ({
  redirect: (path) => dispatch(push(path))
});
type DispatchProps = ReturnType<typeof mapDispatchToProps>;

type OwnProps = {
  jobTerm: JOB_TERM;
  handleOpenForm: (...args) => any;
  openOffersPopper: (...args) => any;
};

type Props = WithStyles<typeof styles> & StateProps & DispatchProps & OwnProps;

const HeaderToolbar = (props: Props) => {
  const { jobTerm, handleOpenForm } = props;
  const isLoading = _keys(props.account).length < 1;
  // console.log(jobTerm);
  if (isLoading) {
    return <LoadingSpinnerScreen />;
  }
  return (
    <Paper className={props.classes.secondAppBar} elevation={0} square>
      <Toolbar className={props.classes.secondToolbar}>
        <div className={props.classes.buttonWrapper}>
          <Fragment>
            <Button
              onClick={props.openOffersPopper}
              color="secondary"
              className={props.classes.toolbarButton}
            >
              <Typography className={props.classes.availableJobOffers} variant="body1">
                <FormattedMessage
                  tagName="span"
                  id={jobTermSuffix(jobTerm, 'masterview.appbar.availableJobs')}
                  values={{
                    jobOffers: <span className="dd">{calcCredits(props.account, jobTerm)}</span>
                  }}
                />
              </Typography>
              <DownIcon />
            </Button>
            {/* <button
              onClick={() => props.redirect(routes.PRICING.build({}, { state: { jobTerm } }))}
              className={props.classes.availableJobOffersLink}
            >
              <FormattedMessage tagName="span" id="masterview.appbar.availableJobs.cta" />
            </button> */}
          </Fragment>
        </div>
        <div className={props.classes.toolbarFlex} />
        {jobTerm !== 'one_time' && (
          <div className={props.classes.buttonWrapper}>
            <img src={idea} alt="Idea" style={{ height: '40px' }} />
            <Typography
              variant="body1"
              style={{ color: '#002f49', paddingLeft: '10px', paddingRight: '30px' }}
            >
              Napište na info@quickjobs.cz pro inzerci přímo na středních a vysokých školách.
            </Typography>
          </div>
        )}
        <div className={props.classes.buttonWrapper}>
          <PrimaryButton onClick={handleOpenForm} corners="rounded">
            <FormattedMessage
              tagName="span"
              id={jobTermSuffix(jobTerm, 'masterview.appbar.createOffer')}
            />
          </PrimaryButton>
        </div>
      </Toolbar>
    </Paper>
  );
};

export const PlainHeaderToolbar = withStyles(styles)(HeaderToolbar);
export default connect(mapStateToProps, mapDispatchToProps)(PlainHeaderToolbar);
