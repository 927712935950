import React, { ComponentType } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import _map from 'lodash/map';
import Helmet from 'react-helmet';
import { Switch, Route } from 'react-router-dom';
import { withRouter } from 'react-router';
import Loadable from 'react-loadable';
import { hot } from 'react-hot-loader';
import { withStyles, WithStyles, Theme } from '@material-ui/core/styles';
import { BrowserView, MobileView } from 'react-device-detect';

import ConsecutiveSnackbars from '../components/ConsecutiveSnackbars';
import LoadingSpinnerScreen from '../components/LoadingSpinnerScreen';
import MobilePhoneScreen from '../containers/MobilePhoneScreen';
import NotFoundPage from '../containers/NotFoundPage';
import AuthorizedContainer from '../containers/AuthorizedContainer';
import AuthenticationContainer from '../containers/AuthenticationContainer';
import MasterView from '../components/MasterView';

import { createStructuredSelector } from 'reselect';
import { oauth as authSelector } from '../store/selectors/authentication';

import {
  auth as authRoutes,
  masterView as masterViewRoutes,
  standalone as standaloneRoutes,
} from '../routes';


const withAuthorizedMasterView = (WrappedComponent: React.ComponentType) => (props: any) => (
  <AuthorizedContainer>
    <MasterView>
      <WrappedComponent {...props} />
    </MasterView>
  </AuthorizedContainer>
);

const withAuthenticationContainer = (WrappedComponent: React.ComponentType) => (props: any) => (
  <AuthenticationContainer>
    <WrappedComponent {...props} />
  </AuthenticationContainer>
);

const mapStateToProps = createStructuredSelector({
  auth: authSelector,
});

const styles = (theme: Theme) => ({
  root: {
    backgroundColor: theme.palette.background.default,
    minHeight: '100vh',
  },
});

interface Props extends WithStyles<typeof styles> {

}

const RouterInit = (props: Props) => {
  return (
    <div className={props.classes.root}>
      <BrowserView>
        <ConsecutiveSnackbars />
        <Helmet defaultTitle="QuickJOBS" />
        <Switch>
          {_map(authRoutes, route => (
            <Route
              key={route.path}
              path={route.path}
              exact={route.exact}
              {...(route.component
                ? {
                  component: withAuthenticationContainer(
                    Loadable({
                      loader: route.component,
                      loading: () => <LoadingSpinnerScreen />,
                    }),
                  ),
                }
                : {})}
            />
          ))}
          {_map(standaloneRoutes, route => (
            <Route
              key={route.path}
              path={route.path}
              exact={route.exact}
              {...(route.component
                ? {
                  component: Loadable({
                    loader: route.component,
                    loading: () => <LoadingSpinnerScreen />,
                  }),
                }
                : {})}
            />
          ))}
          {_map(masterViewRoutes.getDefinitions(), route => (
            <Route
              key={route.path[route.path.length - 1]}
              path={route.path}
              exact={route.exact}
              {...(route.component
                ? {
                  component: withAuthorizedMasterView(
                    Loadable({
                      loader: route.component,
                      loading: () => <LoadingSpinnerScreen />,
                    }),
                  ),
                }
                : {})}
            />
          ))}
          <Route component={NotFoundPage} />
        </Switch>
      </BrowserView>
      <MobileView>
        <MobilePhoneScreen />
      </MobileView>
    </div>
  );
};

export default compose<ComponentType<{}>>(
  hot(module),
  withRouter,
  withStyles(styles),
  connect(
    mapStateToProps,
  ),
)(RouterInit);
