import React, { ComponentType } from "react";
import {compose} from "redux";

import { withStyles, WithStyles } from "@material-ui/core/styles";
import LoadingSpinnerIcon from "../../assets/img/Loading.svg";
import Classnames from "classnames";

import styles from "./styles";
import { LoadingComponentProps } from "react-loadable";

type Props = WithStyles<typeof styles> & {
  className?: string | null;
} & LoadingComponentProps;

const LoadingSpinnerScreen = (props: Props) => {
  const {
    classes,
    className
  } = props;
  return (<div className={Classnames(classes.root, className)}>
      <img className={classes.spinnerIcon} src={LoadingSpinnerIcon} alt="loading" />
  </div>);
};

export default compose<ComponentType<any>>(withStyles(styles))(LoadingSpinnerScreen);