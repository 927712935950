import { createAsyncAction, ActionType } from 'typesafe-actions';
import { UpdateApplicationInput } from 'quickjobs-api-wrapper';

import {
  GET_JOB_APPLICATIONS,
  GET_JOB_APPLICATIONS_SUCCEEDED,
  GET_JOB_APPLICATIONS_FAILED,
  PUSH_JOB_APPLICATION,
  SET_APPLICATION_REACTION,
  SET_APPLICATION_REACTION_SUCCEEDED,
  SET_APPLICATION_REACTION_FAILED
} from '../constants/applications';
import { CollectionResponse } from '../types';
import { Application, QueryParams } from '../types/applications';
import { TYPE_LOGOUT } from './authentication';

type SetApplicationReactionInput = {
  agreedAt: string;
  calledAt: string;
  callLength: number;
};

export const getJobApplications = (id: number, queryParams: QueryParams = {}) => ({
  type: GET_JOB_APPLICATIONS,
  id,
  queryParams
});
type TYPE_GET_JOB_APPLICATIONS = ReturnType<typeof getJobApplications>;

export const getJobApplicationsSucceeded = (
  payload: CollectionResponse<{
    jobId: number;
    applications: Application[];
  }>
) => ({
  type: GET_JOB_APPLICATIONS_SUCCEEDED,
  payload
});
type TYPE_GET_JOB_APPLICATIONS_SUCCEEDED = ReturnType<typeof getJobApplicationsSucceeded>;

export const getJobApplicationsFailed = (jobId: number, error: Error) => ({
  type: GET_JOB_APPLICATIONS_FAILED,
  payload: {
    jobId
  },
  error
});
type TYPE_GET_JOB_APPLICATIONS_FAILED = ReturnType<typeof getJobApplicationsFailed>;

export const updateApplicationAsync = createAsyncAction(
  'APPLICATIONS/UPDATE_REQUEST',
  'APPLICATIONS/UPDATE_SUCCESS',
  'APPLICATIONS/UPDATE_FAILURE'
)<{ id: number; data: UpdateApplicationInput }, Application, { id: number; error: Error }>();

export const setApplicationReaction = (
  id: number,
  action: 'save' | 'reject' | 'wait-for-response' | 'invite-for-next-round' | 'employ',
  applicationInput: SetApplicationReactionInput
) => ({
  type: SET_APPLICATION_REACTION,
  id,
  action,
  applicationInput
});
type TYPE_SET_APPLICATION_REACTION = ReturnType<typeof setApplicationReaction>;

export const setApplicationReactionSucceeded = (payload: Application) => ({
  type: SET_APPLICATION_REACTION_SUCCEEDED,
  payload
});
type TYPE_SET_APPLICATION_REACTION_SUCCEEDED = ReturnType<typeof setApplicationReactionSucceeded>;

export const setApplicationReactionFailed = (id: number, error: Error) => ({
  type: SET_APPLICATION_REACTION_FAILED,
  payload: {
    id
  },
  error
});
type TYPE_SET_APPLICATION_REACTION_FAILED = ReturnType<typeof setApplicationReactionFailed>;

export const pushJobApplication = (payload: Application) => ({
  type: PUSH_JOB_APPLICATION,
  payload
});
type TYPE_PUSH_APPLICATION = ReturnType<typeof pushJobApplication>;

export type ACTIONS =
  | TYPE_GET_JOB_APPLICATIONS
  | TYPE_GET_JOB_APPLICATIONS_SUCCEEDED
  | TYPE_GET_JOB_APPLICATIONS_FAILED
  | TYPE_SET_APPLICATION_REACTION
  | TYPE_SET_APPLICATION_REACTION_SUCCEEDED
  | TYPE_SET_APPLICATION_REACTION_FAILED
  | TYPE_PUSH_APPLICATION
  | TYPE_LOGOUT
  | ActionType<typeof updateApplicationAsync>;
