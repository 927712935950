import React from 'react';
import _keys from 'lodash/keys';
import { replace } from 'connected-react-router';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';

import { createStructuredSelector } from 'reselect';
import { oauth as authSelector } from '../store/selectors/authentication';

import { masterView as restrictedRoutes } from '../routes';

interface OwnProps {
  children: React.ReactNode;
}

type State = {
  isAuthorized: boolean;
};

const mapStateToProps = (state, props) => ({
  location: state.router.location.pathname,
  ...createStructuredSelector({
    auth: authSelector
  })(state)
});
type StateProps = ReturnType<typeof mapStateToProps>;

const mapDispatchToProps = (dispatch: Dispatch) => ({
  replaceUrl: path => dispatch(replace(path))
});
type DispatchProps = ReturnType<typeof mapDispatchToProps>;

interface Props extends OwnProps, StateProps, DispatchProps {
  children: any;
}

class AuthorizedContainer extends React.PureComponent<Props, State> {
  state = {
    isAuthorized: false
  };

  componentDidMount() {
    this.checkAuthorization();
  }

  componentDidUpdate(prevProps: Props) {
    if (prevProps.location !== this.props.location) {
      this.checkAuthorization();
    }
  }

  checkAuthorization() {
    const { auth, location: currentURL } = this.props;

    const isAuthorized = _keys(auth).length > 0;

    this.setState({ isAuthorized });

    const isRestrictedRoute = !!restrictedRoutes.findMatching(currentURL);
    if (!isRestrictedRoute) {
      return;
    }

    if (!isAuthorized) {
      // Trying to reach restricted area but not logged in
      console.warn("Unauthorized");
      this.props.replaceUrl('/login');
    }
  }

  render() {
    if (this.state.isAuthorized) {
      return this.props.children;
    }
    return 'Unauthorized';
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(AuthorizedContainer);
