import { StyleRules } from '@material-ui/core/styles';

export default (): StyleRules => ({
  root: {
    width: '100%',
    height: '100vh',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  '@keyframes rotating': {
    from: {
      WebkitTransform: 'rotate(0deg)',
      OTransform: 'rotate(0deg)',
      transform: 'rotate(0deg)',
    },
    to: {
      WebkitTransform: 'rotate(360deg)',
      OTransform: 'rotate(360deg)',
      transform: 'rotate(360deg)',
    },
  },
  spinnerIcon: {
    marginBottom: '25px',
    WebkitAnimation: 'rotating 2s linear infinite',
    MozAnimation: 'rotating 2s linear infinite',
    MsAnimation: 'rotating 2s linear infinite',
    OAnimation: 'rotating 2s linear infinite',
    animation: 'rotating 2s linear infinite',
  },
});
