import { all, put, takeLatest } from 'redux-saga/effects';
import Api, { JOB_TERM } from 'quickjobs-api-wrapper';
import { push as redirect } from 'connected-react-router';
import { CANCEL_JOB, CREATE_OFFER, GET_JOB, GET_MY_JOBS } from '../../store/constants/jobs';

import {
  cancelJobFailed,
  cancelJobSucceeded,
  createOfferFailed,
  createOfferSucceeded,
  getJobFailed,
  getJobSucceeded,
  getMyJobsFailed,
  getMyJobsSucceeded
} from '../../store/actions/jobs';
import { getJobApplications } from '../../store/actions/applications';
import { push } from '../../store/actions/notification';
import { intl } from '../../utils/globalIntl';
import * as routes from '../../routes/masterview';
import { onTermCondition } from '../../utils/jobTerm';

function* createOffer({ offer, closeFormFunction }: any) {
  try {
    const { data: result } = yield Api.jobs.createJobOffer(offer);
    yield put(createOfferSucceeded(result));
    yield put(push(intl.formatMessage({ id: 'notification.newOffer.created' })));

    yield closeFormFunction();

    const route = onTermCondition(
      result.term,
      routes.ONE_TIME_OFFER_DETAIL,
      routes.LONG_TERM_OFFER_DETAIL,
      routes.FULL_TIME_OFFER_DETAIL
    );
    yield put(redirect(route.build({ id: result.id })));
  } catch (ex) {
    
    yield put(createOfferFailed(ex));

    if(ex.response.errors[0].type==="past_date"){
      yield put(
        push(intl.formatMessage({ id: 'create_offer_error_past_date' }))
      );
    }else if (ex.response.errors[0].type === 'job_out_of_credit') {
      yield put(
        push(intl.formatMessage({ id: 'job_out_of_credit' }), 'snackbar.buy.credit', {
          type: 'redirect',
          path: routes.PRICING.build({}),
          additionalFunction: () => closeFormFunction(),
          state: { jobTerm: offer.term }
        })
      );
    } else {
      yield put(
        push(intl.formatMessage({ id: 'create_offer_error' }))
      );
    }
  }
}

function* getMyJobs({
  queryParams
}: {
  type: typeof GET_MY_JOBS;
  queryParams: { term: JOB_TERM };
}) {
  try {
    const result = yield Api.jobs.myJobs(queryParams);
    yield put(
      getMyJobsSucceeded({
        ...result,
        jobTerm: queryParams.term
      })
    );
  } catch (ex) {
    yield put(getMyJobsFailed(ex, queryParams.term));
    yield put(push(ex.message));
  }
}

function* getJob({ payload }: any) {
  try {
    const result = yield Api.jobs.job(payload.id);
    yield put(getJobSucceeded(result));
  } catch (ex) {
    yield put(getJobFailed(ex));
    yield put(push(ex.message));
  }
}

function* cancelJob({ id }: any) {
  try {
    const result = yield Api.jobs.jobAction(id, 'cancel');
    yield put(getJobApplications(id));
    yield put(cancelJobSucceeded(result));
  } catch (ex) {
    yield put(cancelJobFailed(id, ex));
    yield put(push(ex.message));
  }
}

export default function* jobsWatcher() {
  yield all([
    takeLatest(CREATE_OFFER, createOffer),
    takeLatest(GET_MY_JOBS, getMyJobs),
    takeLatest(GET_JOB, getJob),
    takeLatest(CANCEL_JOB, cancelJob)
  ]);
}
