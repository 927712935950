

import { takeLatest, all, put, select } from 'redux-saga/effects';
import Api from 'quickjobs-api-wrapper';
import { replace as redirect } from 'connected-react-router';
import {
  SEND_VERIFICATION,
  LOGIN,
  REQUEST_FCM_TOKEN,
} from '../../store/constants/authentication';
import {
  sendVerificationFailed,
  sendVerificationSucceeded,
  loginSucceeded,
  loginFailed,
  requestFcmToken,
  requestFcmTokenSucceeded,
} from '../../store/actions/authentication';
import { fetchProfile } from '../../store/actions/user';
import { push } from '../../store/actions/notification';
import { phoneNumber as phoneNumberSelector } from '../../store/selectors/authentication';
import { intl } from '../../utils/globalIntl';
import { requestToken } from '../../utils/sse';

function* sendVerification({ phoneNumber }: any) {
  try {
    const result = yield Api.authorization.sendVerification(phoneNumber);
    yield put(sendVerificationSucceeded(result));
    yield put(push(intl.formatMessage({ id: 'notification.login.codeSend' })));
    yield put(redirect('/login/verification'));
  } catch (ex) {
    yield put(sendVerificationFailed(ex));
    if (
      ex.response &&
      ex.response.errors &&
      ex.response.errors[0].type === 'validation_failed'
    ) {
      yield put(
        push(
          intl.formatMessage({
            id: 'notification.authentication.failed.wrong.format',
          }),
        ),
      );
    } else {
      yield put(push(ex.message));
    }
  }
}

function* login({ verificationCode }: any) {
  const phoneNumber = yield select(phoneNumberSelector);

  try {
    // Attempt to login
    const result = yield Api.authorization.login({
      grantType: 'phone',
      phone: phoneNumber,
      verificationCode,
      scope: 'user_identity',
    });

    // Fetch profile data
    yield put(fetchProfile());

    // Store data
    yield put(loginSucceeded({ oauth: result }));
    yield put(requestFcmToken());

    yield put(redirect('/'));
  } catch (ex) {
    yield put(loginFailed(ex));
    if (
      ex.response &&
      ex.response.errors &&
      ex.response.errors[0].type === 'invalid_verification_code'
    ) {
      yield put(
        push(intl.formatMessage({ id: 'notification.authentication.failed' })),
      );
    } else {
      yield put(push(ex.message));
    }
  }
}

function* requestFcmTokenSaga() {
  try {
    // Attempt to get a new FCM token from the service
    const fcmResponse = yield requestToken();
    // Parse token from response
    const fcmToken = yield fcmResponse.json();
    // Update token on the API
    yield Api.users.updateFcmToken(fcmToken.token);

    // Store data
    yield put(requestFcmTokenSucceeded({ token: fcmToken.token }));
  } catch (ex) {
    yield put(push(ex.message));
  }
}

// $FlowFixMe
export default function* authenticationWatcher() {
  yield all([
    takeLatest(SEND_VERIFICATION, sendVerification),
    takeLatest(LOGIN, login),
    takeLatest(REQUEST_FCM_TOKEN, requestFcmTokenSaga),
  ]);
}
