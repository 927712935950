

import { createSelector } from 'reselect';
import { JobState } from '../types/jobs';
import { JOB_TERM } from 'quickjobs-api-wrapper';
import { onTermCondition } from '../../utils/jobTerm';

const selector = (state): JobState => state.jobs;

export const areJobsLoading = (term: JOB_TERM | "all") => createSelector(selector, state => {
  const detail = state.calls.getDetail.isLoading;
  const oneTime = state.calls.getAllOneTimeOffers.isLoading;
  const longTerm = state.calls.getAllLongTermOffers.isLoading;
  const fullTime = state.calls.getAllFullTimeOffers.isLoading;

  if (term === "all") {
    return oneTime || longTerm || detail;
  }
  return detail || onTermCondition(term, oneTime, longTerm, fullTime);
});

export const getJobListView = createSelector(selector, state => (term: JOB_TERM) => {
  return onTermCondition(term, state.views.allOneTimeOffers, state.views.allLongTermOffers, state.views.allFullTimeOffers);
});

export const getJobsWithinTerm = (term: JOB_TERM) =>
  createSelector([getJobListView, selector], (getView, state) => getView(term).ids.map(id => state.byId[id]).filter(job => !!job));
