

import { takeLatest, all, put, select } from 'redux-saga/effects';

import { PUSH } from '../../store/constants/notification';
import { processQueue, close } from '../../store/actions/notification';
import { open as openSelector } from '../../store/selectors/notification';

function* pushNotification() {
  const open = yield select(openSelector);

  if (open) {
    yield put(close());
  } else {
    yield put(processQueue());
  }
}

// $FlowFixMe
export default function* notificationWatcher() {
  yield all([takeLatest(PUSH, pushNotification)]);
}
