import { replace as _replace } from "lodash"
import { LocationDescriptorObject } from "history";
import { matchPath } from "react-router";

export interface RouteDef {
  path: string | string[];
  name: string;
  exact: boolean;
  groupId?: string;
  component?: () => any;
  render?: () => any;
}

type StringKeyObject = { [key: string]: any };
export interface RouteInstance<P extends StringKeyObject, S extends StringKeyObject, H extends string> {
  getDefinition: () => RouteDef;
  build: (params: P, options?: { state?: S, hash?: H }) => LocationDescriptorObject<S>;
  isMatching: (path: string) => boolean;
}

const create = function <P extends StringKeyObject = {}, S extends StringKeyObject = {}, H extends string = string>(def: RouteDef): RouteInstance<P, S, H> {
  const paths = Array.isArray(def.path) ? def.path : [def.path];
  return {
    getDefinition: () => ({ ...def }),
    isMatching: (path: string) => !!matchPath(path, def),
    build: (params: P, options?: { state?: S, hash?: H }) => {
      let path = paths[paths.length - 1];
      Object.keys(params).forEach((key) => {
        path = _replace(path, `:${key}`, params[key]);
      });

      return {
        pathname: path,
        state: options?.state,
        hash: options?.hash,
      };
      /*
      return {
        is: (stringToCompareWith: string, exact: boolean) => {
          if (exact) {
            return path === stringToCompareWith;
          }
          return _includes(stringToCompareWith, path);
        },
        // isAlias: () => index && (index < paths.length - 1),
        toString: () => path,
        toDescriptor: (): LocationDescriptorObject<S> => ({
          pathname: path,
          state,
        }),
      };
      */
    },
  };
};

const collection = (routes: RouteInstance<any, any, any>[]) => {
  return {
    getDefinitions: () => routes.map(route => route.getDefinition()),
    findMatching: (path: string) => routes.find(route => route.isMatching(path))?.getDefinition(),
  };
};

export type RouteBuild = ReturnType<ReturnType<typeof create>['build']>;

export default {
  create,
  collection,
};
