import _omit from "lodash/omit";

export default (key: string) => (state: any) => {
  if (key === "app") {
    return _omit(state.app, ['newOfferModalOpened', 'newOfferModalInitialFormData', 'newDDBOfferModalOpened', 'newDDBOfferModalInitialFormData']);
  }
  if (key === "jobs") {
    return {
      ...state,
      views: {
        ...state.views,
        allFullTimeOffers: {
          ids: [],
          totalCount: 0,
          pageInfo: null,
        },
      },
    };
  }
  return state;
};
