

export default (key: string) => (state: any) => {
  if (key === 'applications') {
    return state.setIn(['applications', 'jobApplications', 'applications'], {
      byId: {},
      allOneTimeOfferApplicantsIds: [],
    });
  }
  return state;
};
