import React from "react";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import { FormattedMessage } from "react-intl";
import styles from "./styles";

type Props = {
  classes: any;
  history: any;
};

const NotFoundPage = (props: Props) => {
  const {
    classes
  } = props;

  return <div className={classes.root}>
      <div className={classes.oval}>
        <h2>404</h2>
        <p>Error</p>
      </div>
      <div className={classes.body}>
        <h1>
          <FormattedMessage tagName="span" id="404.error.title" />
        </h1>
        <h2>
          <FormattedMessage tagName="span" id="404.error.body" />
        </h2>
        <Button style={{ boxShadow: 'none' }} variant="contained" color="secondary" onClick={() => props.history.goBack()}>
          <FormattedMessage tagName="span" id="404.error.cta" />
        </Button>
      </div>
    </div>;
};

export default withStyles(styles)(NotFoundPage);