

import {
  UPDATE_JOB_OFFER_DRAFT,
  CREATE_OFFER,
  CREATE_OFFER_SUCCEEDED,
  CREATE_OFFER_FAILED,
  GET_MY_JOBS,
  GET_MY_JOBS_SUCCEEDED,
  GET_MY_JOBS_FAILED,
  GET_JOB,
  GET_JOB_SUCCEEDED,
  GET_JOB_FAILED,
  PUSH_NEW_STATS,
  PUSH_JOB_ENDED,
  PUSH_JOB_BANNED,
  CANCEL_JOB,
  CANCEL_JOB_SUCCEEDED,
  CANCEL_JOB_FAILED,
} from '../constants/jobs';

import { QueryParams, JobStats } from '../types/jobs';
import { CollectionResponse } from '../types';
import { TYPE_LOGOUT } from './authentication';
import { Job, JOB_TERM } from 'quickjobs-api-wrapper';
import { FormValues } from '../../forms/NewOffer/props';

export const updateJobOfferDraft = ( payload: { draft: FormValues, term: JOB_TERM }  ) => ({
  type: UPDATE_JOB_OFFER_DRAFT,
  payload
});
type TYPE_UPDATE_JOB_OFFER_DRAFT = ReturnType<typeof updateJobOfferDraft>;

export const createOffer = (
  offer: Partial<Job>,
  closeFormFunction: any,
) => ({
  type: CREATE_OFFER,
  offer,
  closeFormFunction,
});
type TYPE_CREATE_OFFER = ReturnType<typeof createOffer>;

export const createOfferSucceeded = (payload: Job) => ({
  type: CREATE_OFFER_SUCCEEDED,
  payload,
});
type TYPE_CREATE_OFFER_SUCCEEDED = ReturnType<typeof createOfferSucceeded>;

export const createOfferFailed = (error: Error) => ({
  type: CREATE_OFFER_FAILED,
  error,
});
type TYPE_CREATE_OFFER_FAILED = ReturnType<typeof createOfferFailed>;

export const getMyJobs = (queryParams: QueryParams = {}) => ({
  type: GET_MY_JOBS,
  queryParams,
});
type TYPE_GET_MY_JOBS = ReturnType<typeof getMyJobs>;

export const getMyJobsSucceeded = (
  payload: CollectionResponse<{
    jobs: Job[],
    stats: JobStats[],
    jobTerm: JOB_TERM,
  }>,
) => ({
  type: GET_MY_JOBS_SUCCEEDED,
  payload,
});
type TYPE_GET_MY_JOBS_SUCCEEDED = ReturnType<typeof getMyJobsSucceeded>;

export const getMyJobsFailed = (
  error: Error,
  jobTerm: JOB_TERM,
) => ({
  type: GET_MY_JOBS_FAILED,
  payload: { jobTerm },
  error,
});
type TYPE_GET_MY_JOBS_FAILED = ReturnType<typeof getMyJobsFailed>;

export const getJob = (id: number) => ({
  type: GET_JOB,
  payload: {
    id,
  },
});
type TYPE_GET_JOB = ReturnType<typeof getJob>;

export const getJobSucceeded = (payload: { data: Job, stats: JobStats }) => ({
  type: GET_JOB_SUCCEEDED,
  payload,
});
type TYPE_GET_JOB_SUCCEEDED = ReturnType<typeof getJobSucceeded>;

export const getJobFailed = (error: Error) => ({
  type: GET_JOB_FAILED,
  error,
});
type TYPE_GET_JOB_FAILED = ReturnType<typeof getJobFailed>;

export const pushNewStats = (payload: JobStats) => ({
  type: PUSH_NEW_STATS,
  payload,
});
type TYPE_PUSH_NEW_STATS = ReturnType<typeof pushNewStats>;

export const pushJobEnded = (payload: Job) => ({
  type: PUSH_JOB_ENDED,
  payload,
});
type TYPE_PUSH_JOBS_ENDED = ReturnType<typeof pushJobEnded>;

export const pushJobBanned = (payload: Job) => ({
  type: PUSH_JOB_BANNED,
  payload,
});
type TYPE_PUSH_JOBS_BANNED = ReturnType<typeof pushJobBanned>;

export const cancelJob = (id: number) => ({
  type: CANCEL_JOB,
  id,
});
type TYPE_CANCEL_JOB = ReturnType<typeof cancelJob>;

export const cancelJobSucceeded = (payload: Job) => ({
  type: CANCEL_JOB_SUCCEEDED,
  payload,
});
type TYPE_CANCEL_JOB_SUCCEEDED = ReturnType<typeof cancelJobSucceeded>;

export const cancelJobFailed = (
  jobId: Job['id'],
  error: Error,
) => ({
  type: CANCEL_JOB_FAILED,
  jobId,
  error,
});
type TYPE_CANCEL_JOB_FAILED = ReturnType<typeof cancelJobFailed>;

export type ACTIONS =
  | TYPE_UPDATE_JOB_OFFER_DRAFT
  | TYPE_CREATE_OFFER
  | TYPE_CREATE_OFFER_SUCCEEDED
  | TYPE_CREATE_OFFER_FAILED
  | TYPE_GET_MY_JOBS
  | TYPE_GET_MY_JOBS_SUCCEEDED
  | TYPE_GET_MY_JOBS_FAILED
  | TYPE_GET_JOB
  | TYPE_GET_JOB_SUCCEEDED
  | TYPE_GET_JOB_FAILED
  | TYPE_PUSH_NEW_STATS
  | TYPE_PUSH_JOBS_ENDED
  | TYPE_PUSH_JOBS_BANNED
  | TYPE_CANCEL_JOB
  | TYPE_CANCEL_JOB_SUCCEEDED
  | TYPE_CANCEL_JOB_FAILED
  | TYPE_LOGOUT;
