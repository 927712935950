import { takeLatest, all, put } from 'redux-saga/effects';
import Api from 'quickjobs-api-wrapper';

import _get from 'lodash/get';
import _map from 'lodash/map';
import _find from 'lodash/find';
import _omit from 'lodash/omit';

import { GET_JOB_APPLICATIONS, SET_APPLICATION_REACTION } from '../constants/applications';
import {
  getJobApplicationsSucceeded,
  getJobApplicationsFailed,
  setApplicationReactionSucceeded,
  setApplicationReactionFailed,
  updateApplicationAsync
} from '../actions/applications';
import { pushNewStats } from '../actions/jobs';
import { push } from '../actions/notification';

function* getJobApplications({ id, queryParams }: any) {
  try {
    let result = yield Api.applications.getJobApplications(id, queryParams);

    // Merge users into applications
    const applications = _map(_get(result, 'applications', []), application => ({
      ...application,
      candidate: _find(_get(result, 'users', []), ['id', _get(application, 'candidateId', -1)])
    }));

    // Omit irrelevant data
    result = _omit(result, ['users', 'jobs']);
    result.applications = applications;
    result.jobId = id;

    yield put(getJobApplicationsSucceeded(result));
  } catch (ex) {
    yield put(getJobApplicationsFailed(id, ex));
    yield put(push(ex.message));
  }
}

function* updateApplication({
  payload: { id, data }
}: ReturnType<typeof updateApplicationAsync.request>) {
  try {
    const result = yield Api.applications.updateApplication(id, data);
    yield put(updateApplicationAsync.success(result.data));
  } catch (error) {
    yield put(updateApplicationAsync.failure({ id, error }));
    yield put(push(error.message));
  }
}

function* setApplicationReaction({ id, action, applicationInput }: any) {
  try {
    const result = yield Api.applications.resolveApplication(id, action, applicationInput);
    yield put(setApplicationReactionSucceeded(result.data));
    yield put(pushNewStats(result.stats));
  } catch (ex) {
    yield put(setApplicationReactionFailed(id, ex));
    yield put(push(ex.message));
  }
}

// $FlowFixMe
export default function* applicationsWatcher() {
  yield all([
    takeLatest(GET_JOB_APPLICATIONS, getJobApplications),
    takeLatest(SET_APPLICATION_REACTION, setApplicationReaction),
    takeLatest(updateApplicationAsync.request, updateApplication),
  ]);
}
