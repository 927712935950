import _mapValues from 'lodash/mapValues';
import _map from 'lodash/map';
import _isObject from 'lodash/isObjectLike';
import _isArray from 'lodash/isArray';

const deeply = (map: any) => (obj: any, fn: any): any => {
  if (_isArray(obj)) {
    return _map(obj, v => (_isObject(v) ? deeply(map)(v, fn) : v));
  } else {
    return map(
      _mapValues(obj, v => (_isObject(v) ? deeply(map)(v, fn) : v)),
      fn,
    );
  }
};

export default deeply;
