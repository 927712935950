export default {
  API_CONFIG: {
    url: process.env.REACT_APP_API_URL,
    clientId: process.env.REACT_APP_API_CLIENT_ID,
    secret: process.env.REACT_APP_API_CLIENT_SECRET,
    revision: '2.4.0',
  },
  SENTRY_DSN: process.env.REACT_APP_SENTRY_DSN,
  SSE_URL: process.env.REACT_APP_SSE_URL,
  GOOGLE_ANALYTICS_KEY: process.env.REACT_APP_GOOGLE_ANALYTICS_KEY,
  FB_ID: process.env.REACT_APP_FB_ID,
};
