import _reduce from 'lodash/reduceRight';

export const arrayToObject = <T extends {[key: string]: any}>(arr: T[], idParam: string = "id") =>
  _reduce(
    arr,
    (acc, current) => {
      acc[current[idParam]] = current;
      return acc;
    },
    {} as {[key: number]: T},
  );

export const getIdsArray = <T extends {id: number}>(arr: T[]) => arr.map(item => item.id);
