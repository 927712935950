
import React from "react";
import { withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import AppStoreIcon from "../../assets/img/AppStore.svg";
import GooglePlayIcon from "../../assets/img/GooglePlay.svg";
import { FormattedMessage } from "react-intl";
import { QUICKJOBS_WEP_APP_LINK, QUICKJOBS_IOS_APP_LINK, QUICKJOBS_ANDROID_APP_LINK } from "../../constants";
import styles from "./styles";

type Props = {
  classes: {
    root: string;
    divider: string;
    download: string;
  };
};

const MobilePhoneScreen = (props: Props) => {
  const {
    classes
  } = props;
  // Extract protocol and ending slash
  const humanReadableAppUrlReg = QUICKJOBS_WEP_APP_LINK.match(/http(s*):\/\/([a-z.]+)?(\/*)/);
  const humanReadableAppUrl = humanReadableAppUrlReg ? humanReadableAppUrlReg[2] : null;
  return <div className={classes.root}>
      <Typography variant="title">
        <FormattedMessage tagName="span" id="mobile.phone.screen.title" />
      </Typography>
      <div className={classes.divider} />
      <Typography variant="subheading">
        <FormattedMessage tagName="span" values={{
        link: <a href={QUICKJOBS_WEP_APP_LINK}>{QUICKJOBS_WEP_APP_LINK}</a>
      }} id="mobile.phone.screen.subtitle" />
      </Typography>
      <div className={classes.download}>
        <a href={QUICKJOBS_IOS_APP_LINK}>
          <img src={AppStoreIcon} alt="desktop" />
        </a>
        <a href={QUICKJOBS_ANDROID_APP_LINK}>
          <img src={GooglePlayIcon} alt="desktop" />
        </a>
      </div>
      <Typography variant="body1">
        <FormattedMessage tagName="span" id="mobile.phone.screen.divider.text" />
      </Typography>
      <Typography variant="body1">
        <FormattedMessage tagName="span" id="mobile.phone.screen.footer" />
      </Typography>
      <Typography variant="body1">{humanReadableAppUrl}</Typography>
    </div>;
};

export default withStyles(styles)(MobilePhoneScreen);