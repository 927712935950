import immutable from 'immer';
import _map from 'lodash/map';

import {
  GET_JOB_APPLICATIONS,
  GET_JOB_APPLICATIONS_SUCCEEDED,
  GET_JOB_APPLICATIONS_FAILED,
  SET_APPLICATION_REACTION,
  SET_APPLICATION_REACTION_SUCCEEDED,
  SET_APPLICATION_REACTION_FAILED,
  PUSH_JOB_APPLICATION
} from '../constants/applications';
import { LOGOUT } from '../constants/authentication';
import { arrayToObject } from '../../utils/reducerHelpers';
import { ApplicationState, Application } from '../types/applications';
import { ACTIONS, updateApplicationAsync } from '../actions/applications';
import { getType } from 'typesafe-actions';
import { ApplicationEmployerStatement } from 'quickjobs-api-wrapper';

const initialState: ApplicationState = {
  byId: {},
  views: {
    allByJobIds: {}
  },
  calls: {
    setApplicationReaction: {},
    getApplicationsByJob: {},
    updateApplication: {}
  }
};

const setEmployerStatement = (action: string) => {
  if (action === 'save') {
    return ApplicationEmployerStatement.SAVED;
  } else if (action === 'wait-for-response') {
    return ApplicationEmployerStatement.WAITING_FOR_RESPONSE;
  } else if (action === 'invite-for-next-round') {
    return ApplicationEmployerStatement.INVITED_FOR_NEXT_ROUND;
  } else if (action === 'employ') {
    return ApplicationEmployerStatement.EMPLOYED;
  } else if (action === 'reject') {
    return ApplicationEmployerStatement.REJECTED;
  }
  throw new Error(`Unknown application action: ${action}`);
};

export default (state: ApplicationState = initialState, action: ACTIONS): ApplicationState =>
  immutable(state, (draft) => {
    switch (action.type) {
      case LOGOUT:
        return initialState;
      case GET_JOB_APPLICATIONS:
        draft.calls.getApplicationsByJob[action.id] = {
          isLoading: true,
          issuedAt: new Date().getTime(),
          error: null
        };
        break;
      case GET_JOB_APPLICATIONS_SUCCEEDED:
        const appsByJob = draft.calls.getApplicationsByJob[action.payload.jobId];
        if (!appsByJob) {
          break;
        }
        appsByJob.isLoading = false;

        const ids = new Set([
          ..._map(action.payload.applications, (j: Application) => j.id),
          ...(draft.views.allByJobIds[action.payload.jobId] || { ids: [] }).ids
        ]);

        draft.views.allByJobIds[action.payload.jobId] = {
          ids: Array.from(ids),
          totalCount: action.payload.totalCount,
          pageInfo: action.payload.pageInfo
        };

        draft.byId = {
          ...draft.byId,
          ...arrayToObject(action.payload.applications)
        };
        break;
      case GET_JOB_APPLICATIONS_FAILED:
        const appsByJob2 = draft.calls.getApplicationsByJob[action.payload.jobId];
        if (!appsByJob2) {
          break;
        }
        appsByJob2.isLoading = false;
        appsByJob2.error = action.error;
        break;

      case PUSH_JOB_APPLICATION:
        const byJobIds = new Set(
          (draft.views.allByJobIds[action.payload.jobId] || { ids: [] }).ids
        );
        byJobIds.add(action.payload.id);

        draft.byId[action.payload.id] = action.payload;
        (draft.views.allByJobIds[action.payload.jobId] || { ids: [] }).ids = Array.from(byJobIds);

        break;
      case getType(updateApplicationAsync.request): {
        draft.calls.updateApplication[action.payload.id] = {
          isLoading: true,
          issuedAt: Date.now(),
          error: null
        };
        break;
      }
      case getType(updateApplicationAsync.success): {
        const call = draft.calls.updateApplication[action.payload.id];
        if (!call) {
          break;
        }
        call.isLoading = false;
        draft.byId[action.payload.id] = action.payload;
        break;
      }
      case getType(updateApplicationAsync.failure): {
        const call = draft.calls.updateApplication[action.payload.id];
        if (!call) {
          break;
        }
        call.isLoading = false;
        call.error = action.payload.error;
        break;
      }
      case SET_APPLICATION_REACTION:
        draft.calls.setApplicationReaction[action.id] = {
          isLoading: true,
          issuedAt: new Date().getTime(),
          error: null
        };
        draft.byId[action.id] = {
          ...draft.byId[action.id],
          ...action.applicationInput,
          employerStatement: setEmployerStatement(action.action)
        };
        break;
      case SET_APPLICATION_REACTION_SUCCEEDED:
        const setAppReaction = draft.calls.setApplicationReaction[action.payload.id];
        if (!setAppReaction) {
          break;
        }
        setAppReaction.isLoading = false;
        draft.byId[action.payload.id] = action.payload;
        break;
      case SET_APPLICATION_REACTION_FAILED:
        const setAppReaction2 = draft.calls.setApplicationReaction[action.payload.id];
        if (!setAppReaction2) {
          break;
        }
        setAppReaction2.isLoading = false;
        setAppReaction2.error = action.error;
        break;
    }
    return draft;
  });
