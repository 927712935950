

import { takeLatest, all, put } from 'redux-saga/effects';
import Api from 'quickjobs-api-wrapper';

import {
  push as redirect,
  replace as replaceUrl,
} from 'connected-react-router';
import {
  UPDATE_PROFILE,
  FETCH_PENDING_REVIEWS,
  ADD_REVIEW_CANDIDATE,
  FETCH_REVIEWS,
  UPDATE_IMAGE,
  FETCH_ACCOUNT_INFO,
  FETCH_PROFILE,
} from '../../store/constants/user';
import {
  updateProfileSucceeded,
  updateProfileFailed,
  fetchPendingReviewsSucceeded,
  fetchPendingReviewsFailed,
  addReviewCandidateSucceeded,
  addReviewCandidateFailed,
  fetchReviewsSucceeded,
  fetchReviewsFailed,
  updateImageSucceeded,
  updateImageFailed,
  fetchAccountInfoSucceeded,
  fetchAccountInfoFailed,
  fetchProfileSucceeded,
  fetchProfileFailed,
} from '../../store/actions/user';
import { isProfileFilled } from '../../utils/users';
import { push } from '../../store/actions/notification';

import { intl } from '../../utils/globalIntl';

function* updateProfile({ user, path, silent }: any) {
  try {
    const result = yield Api.users.updateProfile(user);
    yield put(updateProfileSucceeded(result));
    if (!silent) {
      yield put(
        push(intl.formatMessage({ id: 'notification.register.updated' })),
      );
    }
    if (path) {
      yield put(redirect(`/${path || ''}`));
    }
  } catch (ex) {
    yield put(updateProfileFailed(ex));
    yield put(push(ex.message));
  }
}

function* fetchPendingReviews() {
  try {
    const result = yield Api.reviews.getPendingReviews();
    // Filter only the reviews targeting workers
    const pendingReviewsForAuthor = result.pendingReviews.filter(
      review => review.candidateId === review.subjectUserId,
    );
    yield put(
      fetchPendingReviewsSucceeded({
        ...result,
        pendingReviews: pendingReviewsForAuthor,
      }),
    );
  } catch (ex) {
    yield put(fetchPendingReviewsFailed(ex));
  }
}

function* fetchReviews() {
  try {
    const result = yield Api.reviews.getMyReviews();
    yield put(fetchReviewsSucceeded(result.reviews));
  } catch (ex) {
    yield put(fetchReviewsFailed(ex));
    yield put(push(ex.message));
  }
}

function* addReviewForCandidate({ id, data }: any) {
  try {
    const result = yield Api.reviews.addReviewCandidate(id, data);
    yield put(addReviewCandidateSucceeded(result.data, id));
    if (data.message === '' && data.rating === 0) {
      yield put(
        push(intl.formatMessage({ id: 'notification.no.review.review' })),
      );
    } else {
      yield put(push(intl.formatMessage({ id: 'reviews.reviewed.sent' })));
    }
  } catch (ex) {
    yield put(addReviewCandidateFailed(ex));
  }
}

function* updateImage({ image, imageType, third }: any) {
  try {
    const result = yield Api.users.updateImage(image, imageType, third);
    yield put(updateImageSucceeded(result.data, imageType));
  } catch (ex) {
    yield put(updateImageFailed(ex));
    yield put(push(ex.message));
  }
}

function* fetchAccountInfo() {
  try {
    const result = yield Api.users.getAccountInfo();
    yield put(fetchAccountInfoSucceeded(result));
  } catch (ex) {
    yield put(fetchAccountInfoFailed(ex));
    yield put(push(ex.message));
  }
}

function* fetchProfile() {
  try {
    const userData = yield Api.users.getProfile();

    if (!isProfileFilled(userData.data)) {
      yield put(replaceUrl('/register'));
    }

    yield put(fetchProfileSucceeded(userData.data));
  } catch (ex) {
    yield put(fetchProfileFailed(ex));
    yield put(push(ex.message));
  }
}

// $FlowFixMe
export default function* userWatcher() {
  yield all([
    takeLatest(UPDATE_PROFILE, updateProfile),
    takeLatest(FETCH_PENDING_REVIEWS, fetchPendingReviews),
    takeLatest(ADD_REVIEW_CANDIDATE, addReviewForCandidate),
    takeLatest(FETCH_REVIEWS, fetchReviews),
    takeLatest(UPDATE_IMAGE, updateImage),
    takeLatest(FETCH_ACCOUNT_INFO, fetchAccountInfo),
    takeLatest(FETCH_PROFILE, fetchProfile),
  ]);
}
