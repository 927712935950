

import { all } from 'redux-saga/effects';

import notification from './notification';
import authentication from './authentication';
import user from './user';
import jobs from './jobs';
import applications from './applications';
import feedback from './feedback';
import service from './service';

// $FlowFixMe
export default function* rootSaga() {
  yield all([
    notification(),
    authentication(),
    user(),
    jobs(),
    applications(),
    feedback(),
    service(),
  ]);
}
