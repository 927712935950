

import immutable from 'immer';

import {
  SWITCH_THEME,
} from '../../store/constants/app';
import { AppState } from '../types/app';
import { ACTIONS } from '../actions/app';

const initialState: AppState = {
  theme: 'light',
};

export default (state: AppState = initialState, action: ACTIONS): AppState =>
  immutable(state, draft => {
    switch (action.type) {
      case SWITCH_THEME:
        draft.theme = draft.theme === 'dark' ? 'light' : 'dark';
        break;
    }
    return draft;
  });
