import { StyleRules, Theme } from "@material-ui/core/styles";

export default (theme: Theme): StyleRules => ({
  paper: {
    height: 90,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
  },
  mainBox: {
    flex: 1,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  caption: {
    fontSize: 10,
    marginBottom: 5,
  },
  colorBox: {
    width: 90,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#f2fbf6',
  },
  iconWrapper: {
    margin: '0 25px',
    color: 'grey',
  },
  textWrapper: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  title: {
    color: theme.palette.primary.main,
    fontWeight: 'bold',
    marginBottom: 5,
  },
  offers: {
    fontSize: '21px',
    fontWeight: 500,
    '& i': {
      fontSize: '11px',
      color: 'rgba(0,47,73,0.6)',
    },
  },
  noAvailableOffers: {
    opacity: '0.4',
  },
  textWrapperText: {
    paddingBottom: '6px',
  },
});
