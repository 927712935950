import migration0 from './0-authentication-without-immutable';
import migration1 from './1-refactored-job-state';
import migration2 from './2-refactored-applicants-state';
import migration3 from './3-refactored-state';
import migration4 from './4-refactored-state';
import migration5 from './5-full-time';
import migration6 from './6-application-employer-statement';

const migrations = [
  migration0,
  migration1,
  migration2,
  migration3,
  migration4,
  migration5,
  migration6
];

export const VERSION = migrations.length - 1;

export default (key: string) =>
  migrations.reduce((acc, migration, id) => {
    acc[id] = migration(key);
    return acc;
  }, {});
