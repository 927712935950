

import React from "react";
import _keys from "lodash/keys";
import _find from "lodash/find";
import { replace } from "connected-react-router";
import { connect } from "react-redux";
import { Dispatch } from "redux";

import { createStructuredSelector } from "reselect";
import { oauth as authSelector } from "../store/selectors/authentication";

import { auth as authRoutes } from "../routes";

type Props = {
  auth: any;
  location: any;
  children: any;
  replaceUrl: (path: string) => void;
};

type State = {
  isAuthorized: boolean;
};

const mapStateToProps = (state) => ({
  location: state.router.location.pathname,
  ...createStructuredSelector({
    auth: authSelector
  })(state)
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  replaceUrl: path => dispatch(replace(path))
});

class AuthenticationContainer extends React.PureComponent<Props, State> {

  state = {
    isAuthorized: false
  };

  componentDidMount() {
    this.checkAuthorization();
  }

  componentDidUpdate(prevProps: Props) {
    if (prevProps.location !== this.props.location) {
      this.checkAuthorization();
    }
  }

  checkAuthorization() {
    const {
      auth,
      location: currentURL
    } = this.props;

    const isAuthorized = _keys(auth).length > 0;

    this.setState({ isAuthorized });

    const isAuthRoute = !!_find(authRoutes, ['path', currentURL]);

    if (!isAuthRoute) {
      return;
    }

    if (isAuthorized) {
      // Trying to reach auth area but already logged in
      this.props.replaceUrl('/');
    }
  }

  render() {
    if (!this.state.isAuthorized) {
      return this.props.children;
    }
    return "Already authorized.";
  }
}


export default connect(mapStateToProps, mapDispatchToProps)(AuthenticationContainer);