import moment from 'moment';

import env from '../config';

/**
  Request new FCM token from the service
  @param oauthToken - string oauth token
 */
export const requestToken = () =>
  fetch(`${env.SSE_URL}/token`, {
    method: 'POST',
    mode: 'cors',
    headers: {
      'Content-Type': 'application/json',
    },
  });

/**
  Prepare connection to Server Sent Events service
  @param fcmToken - string FCM Token provided by the API
 */
export const initEventSource = (fcmToken: string) => {
  // Setup the connection
  const eventSource = new EventSource(
    `${env.SSE_URL}/channels/main?token=${fcmToken}`,
  );

  // Log status
  eventSource.onopen = () => console.info('[SSE] Connection estabilished');
  eventSource.onerror = () => console.error('[SSE] Connection error.');

  // Log default message
  eventSource.onmessage = (message: { data: any }) =>
    console.log('[SSE] Message recieved: ', message);

  return eventSource;
};

export const getSseTokenExpiration = (token: string) => {
  const tokenSplit = token.split('.')[1]; // eslint-disable-line
  const parsedToken = tokenSplit.replace(/-/g, '+').replace(/_/g, '/'); // eslint-disable-line
  return moment(JSON.parse(window.atob(parsedToken)).exp * 1000);
};
