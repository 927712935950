

import React from "react";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import moment from "moment";
import { initEventSource, getSseTokenExpiration } from "../../utils/sse";
import { requestFcmToken } from "../../store/actions/authentication";

import EventSourceContext from "./context";

type State = {
  eventSource: any | null;
};

const mapStateToProps = state => ({
  fcmToken: (state.authentication.fcmToken as string)
});
type StateProps = ReturnType<typeof mapStateToProps>;

const mapDispatchToProps = (dispatch: Dispatch) => ({
  requestFcmTokenFn: () => dispatch(requestFcmToken())
});
type DispatchProps = ReturnType<typeof mapDispatchToProps>;

type Props = StateProps & DispatchProps & {
  children: any;
};

class ServerSentEventsProvider extends React.Component<Props, State> {
  timerToRefresh: any = null;

  state = {
    eventSource: null
  };

  componentDidMount() {
    this.initConnection(this.props.fcmToken);
  }

  componentDidUpdate(prevProps: Props) {
    if (this.props.fcmToken !== prevProps.fcmToken) {
      this.initConnection(this.props.fcmToken);
    }
  }

  componentWillUnmount() {
    this.clearTimer();
  }

  setTimer(token: string) {
    const tokenExpiration = getSseTokenExpiration(token);
    const tokenSecondsToExpiration = moment.duration(tokenExpiration.diff(moment())).asSeconds();
    this.timerToRefresh = setTimeout(() => this.props.requestFcmTokenFn(), (tokenSecondsToExpiration - 60) * 1000);
  }

  clearTimer() {
    if (this.timerToRefresh) {
      clearTimeout(this.timerToRefresh);
      this.timerToRefresh = null;
    }
  }

  initConnection(token: string) {
    if (!token || token === '') {
      // eslint-disable-next-line no-console
      console.warn('No SSE token.');
      return;
    }
    this.clearTimer();
    const sseTokenExpiration = getSseTokenExpiration(token).toDate();
    if (moment().isAfter(sseTokenExpiration)) {
      this.props.requestFcmTokenFn();
      return;
    }
    const eventSource = initEventSource(token);
    this.setTimer(this.props.fcmToken);
    this.setState({
      eventSource
    });
  }

  render() {
    return <EventSourceContext.Provider value={this.state.eventSource}>
        {this.props.children}
      </EventSourceContext.Provider>;
  }
}


export default connect(mapStateToProps, mapDispatchToProps)(ServerSentEventsProvider);